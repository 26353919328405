import React, {useRef, useState} from 'react';
import {Helmet, HelmetProvider} from "react-helmet-async";
import axios from "axios";
import ReactGA from "react-ga4";
import ReactFbq from "react-fbq";

const meta = {
    title: '',
    meta: [],
    link: [],
    style: [],
    script: [],
};

export default function ApplyForm() {
    ReactGA.send({ hitType: "pageview", page: "/apply", title: "Apply Form" });
    ReactFbq.pageView();

    const form = useRef({
        entity_name: null,
        first_name: null,
        last_name: null,
        phone: null,
        email: null,
        credit_score: null,
        num_of_properties_owned: null,
        num_of_sales_in_last_4_years: null,
        property_street_address: null,
        property_street_address2: null,
        property_city: null,
        property_state: null,
        property_zip: null,
        property_type: null,
        loan_type: null,
        num_of_residential_units: null,
        num_of_commercial_units: null,
        current_property_value: null,
        after_renovation_value: null,
        square_foot_added: null,
        is_purchase_or_refinance: null,
        purchase_price: null,
        renovation_budget: null,
        annual_taxes: null,
        annual_insurance_premium: null,
        tenant_in_place: null,
        market_rent: null,
        monthly_hoa: null,
        disclaimer: null,
    })
    const [isLoading, setIsLoading] = useState(false);

    const [currStep, setCurrStep] = useState(1);
    const [entityName, setEntityName] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [creditScore, setCreditScore] = useState('');
    const [numProps, setNumProps] = useState('');
    const [numSales, setNumSales] = useState('');
    const [street, setStreet] = useState('');
    const [street2, setStreet2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [propertyType, setPropertyType] = useState('');
    const [loanType, setLoanType] = useState('');
    const [numResUnits, setNumResUnits] = useState(0);
    const [numComUnits, setNumComUnits] = useState(0);
    const [currPropValue, setCurrPropValue] = useState('');
    const [arv, setArv] = useState('');
    const [sqFtAdded, setSqFtAdded] = useState('');
    const [purchaeOrRefi, setPurchaseOrRefi] = useState('');
    const [purchaseBudget, setPurchaseBudget] = useState('');
    const [payoff, setPayoff] = useState(0);
    const [cashOnPurchase, setCashOnPurchase] = useState(0);
    const [otherCash, setOtherCash] = useState(0);
    const [renoBudget, setRenoBudget] = useState('');
    const [annualTaxes, setAnnualTaxes] = useState('');
    const [annualInsurancePremium, setAnnualInsurancePremium] = useState('');
    const [marketRent, setMarketReunt] = useState(0);
    const [hasTenant, setHasTenant] = useState();
    const [monthlyHoa, setMonthlyHoa] = useState('');
    const [disclaimerAgreed, setDisclaimerAgreed] = useState('');

    const submitForm = () => {
        setIsLoading(true);

        let form = {
            step: currStep,
            first_name: firstName,
            last_name: lastName,
            phone: phone,
            email: email,
            entity_name: entityName,
            credit_score: creditScore,
            num_of_properties_owned: numProps,
            num_of_sales_in_last_4_years: numSales,
            property_street_address: street,
            property_street_address2: street2,
            property_city: city,
            property_state: state,
            property_zip: zip,
            property_type: propertyType,
            loan_type: loanType,
            num_of_residential_units: numResUnits,
            num_of_commercial_units: numComUnits,
            current_property_value: currPropValue,
            after_renovation_value: arv,
            square_foot_added: sqFtAdded,
            is_purchase_or_refinance: purchaeOrRefi,
            purchase_price: purchaseBudget,
            payoff_balance: payoff,
            cash_spent_on_purchase_price: cashOnPurchase,
            money_spent_to_date: otherCash,
            renovation_budget: renoBudget,
            annual_taxes: annualTaxes,
            annual_insurance_premium: annualInsurancePremium,
            monthly_hoa: monthlyHoa,
            disclaimer: disclaimerAgreed,
        };

        if(currStep !== 3) {
            ReactGA.send({ hitType: "pageview", page: "/apply", title: "Step " + currStep + "Submitted" });
            ReactFbq.track({title: 'Apply Form', data: {
                currentStep: currStep
                }
            });


            // Validate Each Step
            axios.post('https://rs.sapieus.com/api/webhooks/external/directlend/verifySteps', form, {
                headers: {
                    "Content-Type": "application/json",
                }
            }).then(res => {
                setIsLoading(false);
                if(res.data.status === 'success') {
                    setCurrStep(currStep + 1);
                }
                else {
                    // handle errors
                    setErrors(res.data.messages);
                    console.log('See errors:', errors);
                }
            }).catch(err => {
                setIsLoading(false);

                console.warn(err);
            })
        }
        else {
            ReactGA.send({ hitType: "pageview", page: "/apply", title: "Form Submitted" });
            ReactFbq.track({title: 'Apply Form Submitted'});

            axios.post('https://rs.sapieus.com/api/webhooks/external/directlend/leadInternal', form, {
                headers: {
                    "Content-Type": "application/json",
                }
            }).then(res => {
                setIsLoading(false);
                console.log(res.data);
                if(res.data.status === 'success') {
                    setIsSuccess(true);
                }
                else {
                    setErrors(res.data.messages);
                }
            }).catch(err => {
                setIsLoading(false);
            })

        }
    }

    const [errors, setErrors] = useState({});
    const [isSuccess, setIsSuccess] = useState(false);

    const [toggleStatus, setToggleStatus] = useState('hidden');
    const toggleNav = () => {
        if(toggleStatus === 'hidden') {
            setToggleStatus('visible');
            document.getElementById('mobileNav').classList.remove('hidden');
        }
        else {
            setToggleStatus('hidden');
            document.getElementById('mobileNav').classList.add('hidden');
        }
    }

    return (
        <React.Fragment>
            <HelmetProvider>
                <Helmet {...meta}></Helmet>
            </HelmetProvider>
            <>
                <section className='pt-6 bg-gray-50 overflow-hidden'>
                    <div className='container mx-auto px-4'>
                        <div className='mb-6'>
                            <div className='flex items-center justify-between px-6 py-3.5 bg-white rounded-full'>
                                <div className='w-auto'>
                                    <div className='flex flex-wrap items-center'>
                                        <div className='w-auto'>
                                            <a href='/'>
                                                <img
                                                    src='/images/PSG-Logo.svg'
                                                    alt='PSG Capital Group Official Logo'
                                                    className='h-20'
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-auto'>
                                    <div className='flex flex-wrap items-center'>
                                        <div className='w-auto hidden lg:block'>
                                            <div className='flex flex-wrap -m-2'>
                                                <div className='w-full md:w-auto p-2'>
                                                    <a
                                                        className='block w-full px-4 py-2.5 text-sm text-center text-gray-900 font-bold bg-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-200 border rounded-full'
                                                        href='/'
                                                    >
                                                        Back to Main Site
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='w-auto lg:hidden'>
                                            <a className='inline-block' href='#' onClick={toggleNav}>
                                                <svg
                                                    className='navbar-burger text-blue-500'
                                                    width={45}
                                                    height={45}
                                                    viewBox='0 0 56 56'
                                                    fill='none'
                                                    xmlns='http://www.w3.org/2000/svg'
                                                >
                                                    <rect
                                                        width={56}
                                                        height={56}
                                                        rx={28}
                                                        fill='currentColor'
                                                    />
                                                    <path
                                                        d='M37 32H19M37 24H19'
                                                        stroke='white'
                                                        strokeWidth='1.5'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                    />
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="mobileNav" className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-4/6 sm:max-w-xs z-50'>
                                <div className='navbar-backdrop fixed inset-0 bg-gray-800 opacity-80'/>
                                <nav className='relative z-10 px-9 pt-8 bg-white h-full overflow-y-auto'>
                                    <div className='flex flex-wrap justify-between h-full'>
                                        <div className='w-full'>
                                            <div className='flex items-center justify-between -m-2'>
                                                <div className='w-auto p-2'>
                                                    <a className='inline-block' href='/'>
                                                        <img
                                                            src='/images/PSG-Logo.svg'
                                                            className="w-full"
                                                            alt=''
                                                        />
                                                    </a>
                                                </div>
                                                <div className='w-auto p-2'>
                                                <a className='inline-block navbar-burger' href='#' onClick={toggleNav}>
                                                        <svg
                                                            width={24}
                                                            height={24}
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            xmlns='http://www.w3.org/2000/svg'
                                                        >
                                                            <path
                                                                d='M6 18L18 6M6 6L18 18'
                                                                stroke='#111827'
                                                                strokeWidth={2}
                                                                strokeLinecap='round'
                                                                strokeLinejoin='round'
                                                            />
                                                        </svg>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex flex-col justify-center py-8 w-full'>
                                            <ul>
                                                <li className='mb-9'>
                                                    <a
                                                        className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                                                        href='/'
                                                    >
                                                        Home
                                                    </a>
                                                </li>
                                                <li className='mb-9'>
                                                    <a
                                                        className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                                                        href='/'
                                                    >
                                                        Serviced Loans
                                                    </a>
                                                </li>
                                                <li className='mb-9'>
                                                    <a
                                                        className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                                                        href='/'
                                                    >
                                                        About our Firm
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                                                        href='/'
                                                    >
                                                        Contact Us
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='flex flex-col justify-end w-full pb-8'>
                                            <div className='flex flex-wrap -m-2'>
                                                <div className='w-full p-2'>
                                                    <a
                                                        className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-green-600 hover:bg-green-700 focus:ring-4 focus:ring-green-200 rounded-full'
                                                        href='/apply'
                                                    >
                                                        Apply Now!
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </nav>
                            </div>
                        </div>
                        <div className='mb-8 p-8 bg-white rounded-3xl'>
                            <div className='flex flex-wrap lg:items-center -m-8'>
                                <div className='w-full p-8'>
                                    <div className='md:max-w-2xl mx-auto'>
                                        <span
                                            className='inline-block mb-3 text-sm text-blue-500 font-bold uppercase tracking-widest'>
                                          Questions? Call us at (561) 208-6560.
                                        </span>
                                        {isSuccess ? (
                                            <>
                                                <h1 className='font-heading mb-4 text-5xl text-gray-900 font-black tracking-tight'>
                                                    You're done! Deal submitted.
                                                </h1>
                                                <p className='mb-6 text-xl font-bold'>
                                                    A loan officer will review the submitted information soon. You'll receive email notifications with any updates as well. Thank you for making PSG Capital Group a choice as your dedicated and promised private money lender.
                                                </p>
                                            </>
                                        ) : (
                                            <>
                                                <h1 className='font-heading mb-4 text-5xl text-gray-900 font-black tracking-tight'>
                                                Apply for your First Deal Today.
                                                </h1>
                                                <p className='mb-6 text-xl font-bold'>
                                                    Use the form below and get funding for your next deal in 7 days!
                                                </p>

                                                <form className='p-10 bg-gray-100 border border-gray-100 rounded-3xl'>
                                                    {isLoading && (
                                                        <div className="mb-4">
                                                            <span className="text-xl">Loading...please wait!</span>
                                                        </div>
                                                    )}
                                                    {currStep === 1 && (
                                                        <>
                                                            <div className='flex flex-wrap -m-5 mb-1'>
                                                                <div className='w-full p-5'>
                                                                    <label
                                                                        className='block mb-2 text-sm text-gray-500 font-bold'
                                                                        htmlFor='business_name'
                                                                    >
                                                                        What is your legal business name?
                                                                    </label>
                                                                    <input
                                                                        className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-green-200 rounded-full'
                                                                        id='business_name'
                                                                        type='text'
                                                                        placeholder='Registered LLC Name'
                                                                        value={entityName}
                                                                        onChange={text => setEntityName(text.target.value)}
                                                                    />
                                                                    {errors && errors.entity_name && (
                                                                        <span
                                                                            className="text-red-700 m-5">ERROR: {errors.entity_name}</span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-wrap -m-5 mb-1">
                                                                <div className="md:w-1/2 w-full p-5">
                                                                    <label
                                                                        className='block mb-2 text-sm text-gray-500 font-bold'
                                                                        htmlFor='first_name'
                                                                    >
                                                                        What is your first name?
                                                                    </label>
                                                                    <input
                                                                        className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-green-200 rounded-full'
                                                                        id='first_name'
                                                                        type='text'
                                                                        placeholder='First Name'
                                                                        value={firstName}
                                                                        onChange={text => setFirstName(text.target.value)}
                                                                    />
                                                                    {errors && errors.first_name && (
                                                                        <span
                                                                            className="text-red-700 m-5">ERROR: {errors.first_name}</span>
                                                                    )}
                                                                </div>
                                                                <div className="md:w-1/2 w-full p-5">
                                                                    <label
                                                                        className='block mb-2 text-sm text-gray-500 font-bold'
                                                                        htmlFor='last_name'
                                                                    >
                                                                        What is your last name?
                                                                    </label>
                                                                    <input
                                                                        className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-green-200 rounded-full'
                                                                        id='last_name'
                                                                        type='text'
                                                                        placeholder='Last Name'
                                                                        value={lastName}
                                                                        onChange={text => setLastName(text.target.value)}
                                                                    />
                                                                    {errors && errors.last_name && (
                                                                        <span
                                                                            className="text-red-700 m-5">ERROR: {errors.last_name}</span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-wrap -m-5 mb-1">
                                                                <div className='w-full md:w-1/2 p-5'>
                                                                    <label
                                                                        className='block mb-2 text-sm text-gray-500 font-bold'
                                                                        htmlFor='email'
                                                                    >
                                                                        What is your email address?
                                                                    </label>
                                                                    <input
                                                                        className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-green-200 rounded-full'
                                                                        id='email'
                                                                        type='text'
                                                                        placeholder='Email address'
                                                                        value={email}
                                                                        onChange={text => setEmail(text.target.value)}
                                                                    />
                                                                    {errors && errors.email && (
                                                                        <span
                                                                            className="text-red-700 m-5">ERROR: {errors.email}</span>
                                                                    )}
                                                                </div>
                                                                <div className='w-full md:w-1/2 p-5'>
                                                                    <label
                                                                        className='block mb-2 text-sm text-gray-500 font-bold'
                                                                        htmlFor='phone'
                                                                    >
                                                                        What is your phone number?
                                                                    </label>
                                                                    <input
                                                                        className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-green-200 rounded-full'
                                                                        id='phone'
                                                                        type='text'
                                                                        placeholder='999-999-9999'
                                                                        value={phone}
                                                                        onChange={text => setPhone(text.target.value)}
                                                                    />
                                                                    {errors && errors.phone && (
                                                                        <span
                                                                            className="text-red-700 m-5">ERROR: {errors.phone}</span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-wrap -m-5 mb-1">
                                                                <div className="w-full md:w-1/2 p-5">
                                                                    <label
                                                                        className='block mb-2 text-sm text-gray-500 font-bold'
                                                                        htmlFor='c_score'
                                                                    >
                                                                        What is your estimated credit score?
                                                                    </label>
                                                                    <input
                                                                        className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-green-200 rounded-full'
                                                                        id='c_score'
                                                                        type='number'
                                                                        placeholder='Numeric Credit Score'
                                                                        value={creditScore}
                                                                        onChange={text => setCreditScore(text.target.value)}
                                                                    />
                                                                    {errors && errors.credit_score && (
                                                                        <span
                                                                            className="text-red-700 m-5">ERROR: {errors.credit_score}</span>
                                                                    )}
                                                                </div>
                                                                <div className="w-full md:w-1/2 p-5">
                                                                    <label
                                                                        className='block mb-2 text-sm text-gray-500 font-bold'
                                                                        htmlFor='numProps'
                                                                    >
                                                                        How many properties have you completed/owned?
                                                                    </label>
                                                                    <input
                                                                        className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-green-200 rounded-full'
                                                                        id='numProps'
                                                                        type='number'
                                                                        placeholder='Number of Properties'
                                                                        value={numProps}
                                                                        onChange={text => setNumProps(text.target.value)}
                                                                    />
                                                                    {errors && errors.num_of_properties_owned && (
                                                                        <span
                                                                            className="text-red-700 m-5">ERROR: {errors.num_of_properties_owned}</span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-wrap -m-5">
                                                                <div className="w-full md:w-1/2 p-5">
                                                                    <label
                                                                        className='block mb-2 text-sm text-gray-500 font-bold'
                                                                        htmlFor='numSales'
                                                                    >
                                                                        How many sales have you completed in the
                                                                        previous 4
                                                                        years?
                                                                    </label>
                                                                    <input
                                                                        className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-green-200 rounded-full'
                                                                        id='numSales'
                                                                        type='number'
                                                                        placeholder='Number of Properties'
                                                                        value={numSales}
                                                                        onChange={text => setNumSales(text.target.value)}
                                                                    />
                                                                    {errors && errors.num_of_sales_in_last_4_years && (
                                                                        <span
                                                                            className="text-red-700 m-5">ERROR: {errors.num_of_sales_in_last_4_years}</span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    {currStep === 2 && (
                                                        <>
                                                            <div className="flex flex-wrap -m-5 mb-1">
                                                                <div className="w-full md:w-1/2 p-5">
                                                                    <label
                                                                        className='block mb-2 text-sm text-gray-500 font-bold'
                                                                        htmlFor='property_street'
                                                                    >
                                                                        What is the street address of the property?
                                                                    </label>
                                                                    <input
                                                                        className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-green-200 rounded-full'
                                                                        id='property_street'
                                                                        type='text'
                                                                        placeholder='Street Address'
                                                                        value={street}
                                                                        onChange={text => setStreet(text.target.value)}
                                                                    />
                                                                    {errors && errors.property_street_address && (
                                                                        <span
                                                                            className="text-red-700 m-5">ERROR: {errors.property_street_address}</span>
                                                                    )}
                                                                </div>
                                                                <div className="w-full md:w-1/2 p-5">
                                                                    <label
                                                                        className='block mb-2 text-sm text-gray-500 font-bold'
                                                                        htmlFor='property_street'
                                                                    >
                                                                        If this is an apartment or condominium, enter
                                                                        the unit
                                                                        number below.
                                                                    </label>
                                                                    <input
                                                                        className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-green-200 rounded-full'
                                                                        id='property_street'
                                                                        type='text'
                                                                        placeholder='Street 2'
                                                                        value={street2}
                                                                        onChange={text => setStreet2(text.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-wrap -m-5 mb-1">
                                                                <div className="w-full md:w-1/3 p-5">
                                                                    <label
                                                                        className='block mb-2 text-sm text-gray-500 font-bold'
                                                                        htmlFor='property_city'
                                                                    >
                                                                        In what city does the property belong?
                                                                    </label>
                                                                    <input
                                                                        className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-green-200 rounded-full'
                                                                        id='property_city'
                                                                        type='text'
                                                                        placeholder='City'
                                                                        value={city}
                                                                        onChange={text => setCity(text.target.value)}
                                                                    />
                                                                    {errors && errors.property_city && (
                                                                        <span
                                                                            className="text-red-700 m-5">ERROR: {errors.property_city}</span>
                                                                    )}
                                                                </div>
                                                                <div className="w-full md:w-1/3 p-5">
                                                                    <label
                                                                        className='block mb-2 text-sm text-gray-500 font-bold'
                                                                        htmlFor='property_zip'
                                                                    >
                                                                        What is the zip/postal code of the property?
                                                                    </label>
                                                                    <input
                                                                        className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-green-200 rounded-full'
                                                                        id='property_zip'
                                                                        type='number'
                                                                        placeholder='Zip Code'
                                                                        value={zip}
                                                                        onChange={text => setZip(text.target.value)}
                                                                    />
                                                                    {errors && errors.property_zip && (
                                                                        <span
                                                                            className="text-red-700 m-5">ERROR: {errors.property_zip}</span>
                                                                    )}
                                                                </div>
                                                                <div className="w-full md:w-1/3 p-5">
                                                                    <label
                                                                        className='block mb-2 text-sm text-gray-500 font-bold'
                                                                        htmlFor='property_state'
                                                                    >
                                                                        What state is the property located in?
                                                                    </label>
                                                                    <select
                                                                        className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-green-200 rounded-full'
                                                                        id='property_state'
                                                                        placeholder='State'
                                                                        value={state}
                                                                        onChange={text => setState(text.target.value)}
                                                                    >
                                                                        <option value="">Please Choose</option>
                                                                        <option value="AL">Alabama</option>
                                                                        <option value="AK">Alaska</option>
                                                                        <option value="AZ">Arizona</option>
                                                                        <option value="AR">Arkansas</option>
                                                                        <option value="CA">California</option>
                                                                        <option value="CO">Colorado</option>
                                                                        <option value="CT">Connecticut</option>
                                                                        <option value="DE">Delaware</option>
                                                                        <option value="DC">District Of Columbia</option>
                                                                        <option value="FL">Florida</option>
                                                                        <option value="GA">Georgia</option>
                                                                        <option value="HI">Hawaii</option>
                                                                        <option value="ID">Idaho</option>
                                                                        <option value="IL">Illinois</option>
                                                                        <option value="IN">Indiana</option>
                                                                        <option value="IA">Iowa</option>
                                                                        <option value="KS">Kansas</option>
                                                                        <option value="KY">Kentucky</option>
                                                                        <option value="LA">Louisiana</option>
                                                                        <option value="ME">Maine</option>
                                                                        <option value="MD">Maryland</option>
                                                                        <option value="MA">Massachusetts</option>
                                                                        <option value="MI">Michigan</option>
                                                                        <option value="MN">Minnesota</option>
                                                                        <option value="MS">Mississippi</option>
                                                                        <option value="MO">Missouri</option>
                                                                        <option value="MT">Montana</option>
                                                                        <option value="NE">Nebraska</option>
                                                                        <option value="NV">Nevada</option>
                                                                        <option value="NH">New Hampshire</option>
                                                                        <option value="NJ">New Jersey</option>
                                                                        <option value="NM">New Mexico</option>
                                                                        <option value="NY">New York</option>
                                                                        <option value="NC">North Carolina</option>
                                                                        <option value="ND">North Dakota</option>
                                                                        <option value="OH">Ohio</option>
                                                                        <option value="OK">Oklahoma</option>
                                                                        <option value="OR">Oregon</option>
                                                                        <option value="PA">Pennsylvania</option>
                                                                        <option value="RI">Rhode Island</option>
                                                                        <option value="SC">South Carolina</option>
                                                                        <option value="SD">South Dakota</option>
                                                                        <option value="TN">Tennessee</option>
                                                                        <option value="TX">Texas</option>
                                                                        <option value="UT">Utah</option>
                                                                        <option value="VT">Vermont</option>
                                                                        <option value="VA">Virginia</option>
                                                                        <option value="WA">Washington</option>
                                                                        <option value="WV">West Virginia</option>
                                                                        <option value="WI">Wisconsin</option>
                                                                        <option value="WY">Wyoming</option>
                                                                    </select>
                                                                    {errors && errors.property_state && (
                                                                        <span
                                                                            className="text-red-700 m-5">ERROR: {errors.property_state}</span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-wrap -m-5 mb-1">
                                                                <div className="w-full md:w-1/2 p-5">
                                                                    <label
                                                                        className='block mb-2 text-sm text-gray-500 font-bold'
                                                                        htmlFor='property_type'
                                                                    >
                                                                        What type of property is this?
                                                                    </label>
                                                                    <select
                                                                        className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-green-200 rounded-full'
                                                                        id='property_type'
                                                                        placeholder='City'
                                                                        value={propertyType}
                                                                        onChange={text => setPropertyType(text.target.value)}
                                                                    >
                                                                        <option value="">Please choose.</option>
                                                                        <option value="Condominium">Condominium</option>
                                                                        <option value="Small Residential (1-4)">Small
                                                                            Residential (1-4)
                                                                        </option>
                                                                        <option value="Multi Family">Multi Family
                                                                        </option>
                                                                        <option value="Mixed Use">Mixed Use</option>
                                                                        <option value="Office">Office</option>
                                                                        <option value="Warehouse">Warehouse</option>
                                                                        <option value="Retail">Retail</option>
                                                                    </select>
                                                                    {errors && errors.property_type && (
                                                                        <span
                                                                            className="text-red-700 m-5">ERROR: {errors.property_type}</span>
                                                                    )}
                                                                </div>
                                                                <div className="w-full md:w-1/2 p-5">
                                                                    <label
                                                                        className='block mb-2 text-sm text-gray-500 font-bold'
                                                                        htmlFor='loan_type'
                                                                    >
                                                                        What type of loan/project is this?
                                                                    </label>
                                                                    <select
                                                                        className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-green-200 rounded-full'
                                                                        id='loan_type'
                                                                        placeholder='Loan Type'
                                                                        value={loanType}
                                                                        onChange={text => setLoanType(text.target.value)}
                                                                    >
                                                                        <option value="">Please choose.</option>
                                                                        <option value="Fix & Flip">Fix & Flip</option>
                                                                        <option value="Multi-Family">Multi Family
                                                                        </option>
                                                                        <option value="Ground Up">Ground Up</option>
                                                                        <option value="Rental">Rental</option>
                                                                        <option value="Commercial">Commercial</option>
                                                                    </select>
                                                                    {errors && errors.loan_type && (
                                                                        <span
                                                                            className="text-red-700 m-5">ERROR: {errors.loan_type}</span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            {loanType === "Rental" && (
                                                                <div className="flex flex-wrap -m-5 mb-1">
                                                                    <div className="w-full md:w-1/2 p-5">
                                                                        <label
                                                                            className='block mb-2 text-sm text-gray-500 font-bold'
                                                                            htmlFor='hasTenant'
                                                                        >
                                                                            Does the rental currently have a tenant in
                                                                            place?
                                                                        </label>
                                                                        <select
                                                                            className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-green-200 rounded-full'
                                                                            id='hasTenant'
                                                                            placeholder='Property Value'
                                                                            value={hasTenant}
                                                                            onChange={text => setHasTenant(text.target.value)}
                                                                        >
                                                                            <option value="No">No</option>
                                                                            <option value="Yes">Yes</option>
                                                                        </select>
                                                                        {errors && errors.tenant_in_place && (
                                                                            <span
                                                                                className="text-red-700 m-5">ERROR: {errors.tenant_in_place}</span>
                                                                        )}
                                                                    </div>
                                                                    <div className="w-full md:w-1/2 p-5">
                                                                        <label
                                                                            className='block mb-2 text-sm text-gray-500 font-bold'
                                                                            htmlFor='marketRent'
                                                                        >
                                                                            What is the market rent, in dollars ($)?
                                                                        </label>
                                                                        <input
                                                                            className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-green-200 rounded-full'
                                                                            id='marketRent'
                                                                            placeholder='Monthly Dollar Value of Rent'
                                                                            type="number"
                                                                            value={marketRent}
                                                                            onChange={text => setMarketReunt(text.target.value)}
                                                                        />
                                                                        {errors && errors.market_rent && (
                                                                            <span
                                                                                className="text-red-700 m-5">ERROR: {errors.market_rent}</span>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <div className="flex flex-wrap -m-5 mb-1">
                                                                <div className="w-full md:w-1/2 p-5">
                                                                    <label
                                                                        className='block mb-2 text-sm text-gray-500 font-bold'
                                                                        htmlFor='prop_value'
                                                                    >
                                                                        What is the current property value, in dollars
                                                                        ($)?
                                                                    </label>
                                                                    <input
                                                                        className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-green-200 rounded-full'
                                                                        id='prop_value'
                                                                        type='number'
                                                                        placeholder='Property Value'
                                                                        value={currPropValue}
                                                                        onChange={text => setCurrPropValue(text.target.value)}
                                                                    />
                                                                    {errors && errors.current_property_value && (
                                                                        <span
                                                                            className="text-red-700 m-5">ERROR: {errors.current_property_value}</span>
                                                                    )}
                                                                </div>
                                                                <div className="w-full md:w-1/2 p-5">
                                                                    <label
                                                                        className='block mb-2 text-sm text-gray-500 font-bold'
                                                                        htmlFor='sqFtAdded'
                                                                    >
                                                                        Is square footage being added?
                                                                    </label>
                                                                    <select
                                                                        className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-green-200 rounded-full'
                                                                        id='sqFtAdded'
                                                                        placeholder='Sq Ft Added Y/N'
                                                                        value={sqFtAdded}
                                                                        onChange={text => setSqFtAdded(text.target.value)}
                                                                    >
                                                                        <option value="No">No</option>
                                                                        <option value="Yes">Yes</option>
                                                                    </select>
                                                                    {errors && errors.square_foot_added && (
                                                                        <span
                                                                            className="text-red-700 m-5">ERROR: {errors.square_foot_added}</span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-wrap -m-5 mb-1">
                                                                <div className="w-full md:w-1/2 p-5">
                                                                    <label
                                                                        className='block mb-2 text-sm text-gray-500 font-bold'
                                                                        htmlFor='numResUnits'
                                                                    >
                                                                        How many residential units are at the property?
                                                                    </label>
                                                                    <input
                                                                        className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-green-200 rounded-full'
                                                                        id='numResUnits'
                                                                        type='number'
                                                                        placeholder='# of Residential Units'
                                                                        value={numResUnits}
                                                                        onChange={text => setNumResUnits(text.target.value)}
                                                                    />
                                                                    {errors && errors.num_of_residential_units && (
                                                                        <span
                                                                            className="text-red-700 m-5">ERROR: {errors.num_of_residential_units}</span>
                                                                    )}
                                                                </div>
                                                                <div className="w-full md:w-1/2 p-5">
                                                                    <label
                                                                        className='block mb-2 text-sm text-gray-500 font-bold'
                                                                        htmlFor='numComUnits'
                                                                    >
                                                                        How many commercial units are at the property?
                                                                    </label>
                                                                    <input
                                                                        className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-green-200 rounded-full'
                                                                        id='numComUnits'
                                                                        type='number'
                                                                        placeholder='# of Commercial Units'
                                                                        value={numComUnits}
                                                                        onChange={text => setNumComUnits(text.target.value)}
                                                                    />
                                                                    {errors && errors.num_of_commercial_units && (
                                                                        <span
                                                                            className="text-red-700 m-5">ERROR: {errors.num_of_commercial_units}</span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    {currStep === 3 && (
                                                        <>
                                                            <div className="flex flex-wrap -m-5 mb-1">
                                                                <div className="w-full md:w-1/2 p-5">
                                                                    <label
                                                                        className='block mb-2 text-sm text-gray-500 font-bold'
                                                                        htmlFor='purchaseOrRefi'
                                                                    >
                                                                        Is this a purchase or a refinance?
                                                                    </label>
                                                                    <select
                                                                        className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-green-200 rounded-full'
                                                                        id='purchaseOrRefi'
                                                                        placeholder='Purchase or Refi'
                                                                        value={purchaeOrRefi}
                                                                        onChange={text => setPurchaseOrRefi(text.target.value)}
                                                                    >
                                                                        <option value="">Please Choose</option>
                                                                        <option value="Purchase">Purchase</option>
                                                                        <option value="Refinance">Refinance</option>
                                                                    </select>
                                                                    {errors && errors.is_purchase_or_refinance && (
                                                                        <span
                                                                            className="text-red-700 m-5">ERROR: {errors.is_purchase_or_refinance}</span>
                                                                    )}
                                                                </div>
                                                                <div className="w-full md:w-1/2 p-5">
                                                                    <label
                                                                        className='block mb-2 text-sm text-gray-500 font-bold'
                                                                        htmlFor='renoBudget'
                                                                    >
                                                                        What is your renovation budget?
                                                                    </label>
                                                                    <input
                                                                        className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-green-200 rounded-full'
                                                                        id='renoBudget'
                                                                        type='number'
                                                                        placeholder=''
                                                                        value={renoBudget}
                                                                        onChange={text => setRenoBudget(text.target.value)}
                                                                    />
                                                                    {errors && errors.renovation_budget && (
                                                                        <span
                                                                            className="text-red-700 m-5">ERROR: {errors.renovation_budget}</span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-wrap -m-5 mb-1">
                                                                <div className="w-full md:w-1/2 p-5">
                                                                    <label
                                                                        className='block mb-2 text-sm text-gray-500 font-bold'
                                                                        htmlFor='purchBudget'
                                                                    >
                                                                        What is your purchase price/budget?
                                                                    </label>
                                                                    <input
                                                                        className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-green-200 rounded-full'
                                                                        id='purchBudget'
                                                                        type='number'
                                                                        placeholder=''
                                                                        value={purchaseBudget}
                                                                        onChange={text => setPurchaseBudget(text.target.value)}
                                                                    />
                                                                    {errors && errors.purchase_price && (
                                                                        <span
                                                                            className="text-red-700 m-5">ERROR: {errors.purchase_price}</span>
                                                                    )}
                                                                </div>
                                                                {purchaeOrRefi === 'Refinance' && (
                                                                    <>
                                                                        <div className="w-full md:w-1/2 p-5">
                                                                            <label
                                                                                className='block mb-2 text-sm text-gray-500 font-bold'
                                                                                htmlFor='payoffBalance'
                                                                            >
                                                                                What is the payoff balance, if any?
                                                                            </label>
                                                                            <input
                                                                                className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-green-200 rounded-full'
                                                                                id='payoffBalance'
                                                                                type='number'
                                                                                placeholder=''
                                                                                value={payoff}
                                                                                onChange={text => setPayoff(text.target.value)}
                                                                            />
                                                                        </div>
                                                                        <div className="w-full md:w-1/2 p-5">
                                                                            <label
                                                                                className='block mb-2 text-sm text-gray-500 font-bold'
                                                                                htmlFor='cashSpent'
                                                                            >
                                                                                How much cash has been spent on the
                                                                                purchase, if
                                                                                any?
                                                                            </label>
                                                                            <input
                                                                                className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-green-200 rounded-full'
                                                                                id='cashSpent'
                                                                                type='number'
                                                                                placeholder=''
                                                                                value={cashOnPurchase}
                                                                                onChange={text => setCashOnPurchase(text.target.value)}
                                                                            />
                                                                        </div>
                                                                        <div className="w-full md:w-1/2 p-5">
                                                                            <label
                                                                                className='block mb-2 text-sm text-gray-500 font-bold'
                                                                                htmlFor='otherCash'
                                                                            >
                                                                                How much other cash/costs/money has been
                                                                                spent to date?
                                                                            </label>
                                                                            <input
                                                                                className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-green-200 rounded-full'
                                                                                id='otherCash'
                                                                                type='number'
                                                                                placeholder=''
                                                                                value={otherCash}
                                                                                onChange={text => setOtherCash(text.target.value)}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                            <div className="flex flex-wrap -m-5 mb-1">
                                                                <div className="w-full md:w-1/2 p-5">
                                                                    <label
                                                                        className='block mb-2 text-sm text-gray-500 font-bold'
                                                                        htmlFor='arv'
                                                                    >
                                                                        What is expected after-renovation value of the
                                                                        property?
                                                                    </label>
                                                                    <input
                                                                        className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-green-200 rounded-full'
                                                                        id='arv'
                                                                        type='number'
                                                                        placeholder=''
                                                                        value={arv}
                                                                        onChange={text => setArv(text.target.value)}
                                                                    />
                                                                    {errors && errors.after_renovation_value && (
                                                                        <span
                                                                            className="text-red-700 m-5">ERROR: {errors.after_renovation_value}</span>
                                                                    )}
                                                                </div>
                                                                <div className="w-full md:w-1/2 p-5">
                                                                    <label
                                                                        className='block mb-2 text-sm text-gray-500 font-bold'
                                                                        htmlFor='annualTax'
                                                                    >
                                                                        What is the annual tax amount on the property?
                                                                    </label>
                                                                    <input
                                                                        className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-green-200 rounded-full'
                                                                        id='annualTax'
                                                                        type='number'
                                                                        placeholder=''
                                                                        value={annualTaxes}
                                                                        onChange={text => setAnnualTaxes(text.target.value)}
                                                                    />
                                                                    {errors && errors.annual_taxes && (
                                                                        <span
                                                                            className="text-red-700 m-5">ERROR: {errors.annual_taxes}</span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-wrap -m-5 mb-1">
                                                                <div className="w-full md:w-1/2 p-5">
                                                                    <label
                                                                        className='block mb-2 text-sm text-gray-500 font-bold'
                                                                        htmlFor='annualTaxPrem'
                                                                    >
                                                                        What is the annual insurance premium?
                                                                    </label>
                                                                    <input
                                                                        className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-green-200 rounded-full'
                                                                        id='annualTaxPrem'
                                                                        type='number'
                                                                        placeholder=''
                                                                        value={annualInsurancePremium}
                                                                        onChange={text => setAnnualInsurancePremium(text.target.value)}
                                                                    />
                                                                    {errors && errors.annual_insurance_premium && (
                                                                        <span
                                                                            className="text-red-700 m-5">ERROR: {errors.annual_insurance_premium}</span>
                                                                    )}
                                                                </div>
                                                                <div className="w-full md:w-1/2 p-5">
                                                                    <label
                                                                        className='block mb-2 text-sm text-gray-500 font-bold'
                                                                        htmlFor='hoa'
                                                                    >
                                                                        What is the monthly HOA (if applicable)?
                                                                    </label>
                                                                    <input
                                                                        className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-green-200 rounded-full'
                                                                        id='hoa'
                                                                        type='number'
                                                                        placeholder=''
                                                                        value={monthlyHoa}
                                                                        onChange={text => setMonthlyHoa(text.target.value)}
                                                                    />
                                                                    {errors && errors.monthly_hoa && (
                                                                        <span
                                                                            className="text-red-700 m-5">ERROR: {errors.monthly_hoa}</span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-wrap -m-5">
                                                                <div className="w-full p-5">
                                                                    <input id="disclaimerStatement" type="checkbox"
                                                                           className="w-6 h-6 mr-2" value={disclaimerAgreed} onChange={text => setDisclaimerAgreed(text.target.checked)} />
                                                                    <label htmlFor="disclaimerStatement">By checking
                                                                        this box,
                                                                        you authorize - with respect to the telephone
                                                                        number
                                                                        provided on this form and any other telephone
                                                                        number
                                                                        associated
                                                                        or related to you that may be provided to us in
                                                                        the
                                                                        future — PSG Capital Group to contact you by
                                                                        phone,
                                                                        text, email, mail, or by artificial or
                                                                        pre-recorded
                                                                        voice, even if you have listed yourself on any
                                                                        Do-Not-Call List. You also agree to our <a
                                                                            className="text-blue-500"
                                                                            href="https://psgcapitalgroup.com/terms"
                                                                            target="_blank">Terms &amp; Conditions</a> and <a
                                                                            href="https://psgcapitalgroup.com/privacy-policy/"
                                                                            target="_blank" className="text-blue-500">Privacy
                                                                            Policy</a>, and further
                                                                        agree that any person or entity contacting you
                                                                        pursuant
                                                                        to your consent here may use an automatic
                                                                        telephone
                                                                        dialing system, even if the telephone number you
                                                                        provided above (or any telephone number
                                                                        associated with
                                                                        you) is connected to a mobile phone and even if
                                                                        you
                                                                        might be charged for the call. Your consent to
                                                                        receive
                                                                        automated calls or text messages is not required
                                                                        for any
                                                                        purchase or availability of goods/services from
                                                                        PSG
                                                                        Capital Group; if you do not consent, you may
                                                                        call us at
                                                                        844-PSGMONEY to continue your inquiry. You have
                                                                        the
                                                                        right to withdraw your consent at any time by
                                                                        simply
                                                                        replying STOP or by calling us at 844-PSGMONEY.</label>
                                                                </div>
                                                                {errors && errors.disclaimer && (
                                                                    <span
                                                                        className="text-red-700 m-5">ERROR: {errors.disclaimer}</span>
                                                                )}
                                                            </div>
                                                        </>
                                                    )}

                                                    <div className='flex flex-wrap -m-3.5 pt-6'>
                                                        <div className='w-full p-3.5'>
                                                            <div className='flex flex-wrap items-center -m-2'>
                                                                <div className='w-full p-2'>
                                                                    <div className='flex md:justify-end -m-2'>
                                                                        {currStep !== 1 && (
                                                                            <div className='w-full md:w-auto p-2'>
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={() => setCurrStep(currStep - 1)}
                                                                                    className='w-full px-8 md:px-16 py-3.5 text-lg text-center text-gray-600 font-bold bg-gray-200 hover:bg-gray-50 focus:ring-4 focus:ring-green-200 rounded-full'
                                                                                >
                                                                                    Go Back
                                                                                </button>
                                                                            </div>
                                                                        )}
                                                                        <div className='w-full md:w-auto p-2'>
                                                                            {isLoading ? (
                                                                                <div>
                                                                                    <span className="text-xl">Loading...please wait.</span>
                                                                                </div>
                                                                            ) : (
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={() => submitForm()}
                                                                                    className='block w-full px-8 md:px-16 py-3.5 text-lg text-center text-white font-bold bg-green-600 hover:bg-green-700 focus:ring-4 focus:ring-green-200 rounded-full'
                                                                                >
                                                                                    {currStep === 3 ? 'Submit Deal!' : 'Next Step'}
                                                                                </button>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='py-10 bg-gray-50 overflow-hidden'>
                    <div className='container mx-auto px-4'>
                        <div className='py-16 px-8 bg-white rounded-3xl'>
                            <div className='flex flex-col justify-center'>
                                <a className='inline-block max-w-max mx-auto mb-10' href='/'>
                                    <img
                                        src='/images/PSG-Logo.svg'
                                        alt='PSG Capital Group Official Logo'
                                        className='text-center m-auto w-1/2'
                                    />
                                </a>
                                <ul className='-m-6 mb-7 text-center'>
                                    <li className='inline-flex p-6'>
                                        <a
                                            className='inline-block text-gray-500 hover:text-gray-600 font-bold hover:cursor-pointer'
                                            href="/"
                                        >
                                            Serviced Loans
                                        </a>
                                    </li>
                                    <li className='inline-flex p-6'>
                                        <a
                                            className='inline-block text-gray-500 hover:text-gray-600 font-bold hover:cursor-pointer'
                                            href="/"
                                        >
                                            About our Firm
                                        </a>
                                    </li>
                                    <li className='inline-flex p-6'>
                                        <a
                                            className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                                            href='/apply'
                                        >
                                            Pre-Approval Form
                                        </a>
                                    </li>
                                    <li className='inline-flex p-6'>
                                        <a
                                            className='inline-block text-gray-500 hover:text-gray-600 font-bold hover:cursor-pointer'
                                            href="/"
                                        >
                                            Contact Us
                                        </a>
                                    </li>
                                    {/*<li className='inline-flex p-6'>*/}
                                    {/*    <a*/}
                                    {/*        className='inline-block text-gray-500 hover:text-gray-600 font-bold'*/}
                                    {/*        href='#'*/}
                                    {/*    >*/}
                                    {/*        Affiliates*/}
                                    {/*    </a>*/}
                                    {/*</li>*/}
                                </ul>
                                <div className='flex flex-wrap justify-center -m-4 mb-12'>
                                    {/*<div className='w-auto p-4'>*/}
                                    {/*    <a className='block' href='#'>*/}
                                    {/*        <svg className="h-8 w-8 text-green-500" width="24" height="24"*/}
                                    {/*             viewBox="0 0 24 24"*/}
                                    {/*             strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"*/}
                                    {/*             strokeLinejoin="round">*/}
                                    {/*            <path stroke="none" d="M0 0h24v24H0z"/>*/}
                                    {/*            <rect x="4" y="4" width="16" height="16" rx="4"/>*/}
                                    {/*            <circle cx="12" cy="12" r="3"/>*/}
                                    {/*            <line x1="16.5" y1="7.5" x2="16.5" y2="7.501"/>*/}
                                    {/*        </svg>*/}
                                    {/*    </a>*/}
                                    {/*</div>*/}
                                    {/*<div className='w-auto p-4'>*/}
                                    {/*    <a className='block' href='#'>*/}
                                    {/*        <svg className="h-8 w-8 text-green-500" width="24" height="24"*/}
                                    {/*             viewBox="0 0 24 24"*/}
                                    {/*             strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"*/}
                                    {/*             strokeLinejoin="round">*/}
                                    {/*            <path stroke="none" d="M0 0h24v24H0z"/>*/}
                                    {/*            <path*/}
                                    {/*                d="M22 4.01c-1 .49-1.98.689-3 .99-1.121-1.265-2.783-1.335-4.38-.737S11.977 6.323 12 8v1c-3.245.083-6.135-1.395-8-4 0 0-4.182 7.433 4 11-1.872 1.247-3.739 2.088-6 2 3.308 1.803 6.913 2.423 10.034 1.517 3.58-1.04 6.522-3.723 7.651-7.742a13.84 13.84 0 0 0 .497 -3.753C20.18 7.773 21.692 5.25 22 4.009z"/>*/}
                                    {/*        </svg>*/}
                                    {/*    </a>*/}
                                    {/*</div>*/}
                                    <div className='w-auto p-4'>
                                        <a className='block' href='https://www.facebook.com/psgcapitalgroup' target='_blank'>
                                            <svg className="h-8 w-8 text-green-500" width="24" height="24"
                                                 viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"
                                                 strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z"/>
                                                <path
                                                    d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"/>
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                                <p className='text-center font-bold'>
                                    <span>Ⓒ Copyright. All rights reserved by </span>
                                    <a className='text-blue-500 hover:text-blue-600' href='#'>
                                        PSG Capital Group
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        </React.Fragment>
    )
}
