import React from 'react';
import {
  Routes,
  Route,
} from 'react-router-dom';

import IndexPage from './pages/Index.js';
import ApplyForm from "./ApplyForm";
import ReactGA from "react-ga4";

function App() {
    ReactGA.initialize([
        {
            trackingId: "G-019RVFK4BK",
        },
    ]);

  return (
    <Routes>
      <Route path="/" element={<IndexPage />} />
      <Route path="/apply" element={<ApplyForm />} />
    </Routes>
  );
}

export default App;
