import React, {useRef, useState} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ReactGA from "react-ga4";
import ReactFbq from "react-fbq";

const meta = {
  title: '',
  meta: [],
  link: [],
  style: [],
  script: [],
};
export default function Index() {
  ReactGA.send({ hitType: "pageview", page: "/", title: "Home Page Viewed" });
  ReactFbq.pageView();
  // ReactFbq.track({title: 'Home', data: 'data' });


  const loansArea = useRef();
  const aboutArea = useRef();
  const contactArea = useRef();
  const learnArea = useRef();

  // const shownFaqElement = useRef(1);
  const [shownFaqElement, setShownFaqElement] = useState(1);
  const toggleShownFaqElement = (elementId) => {
    setShownFaqElement(elementId);
  }

  const [toggleStatus, setToggleStatus] = useState('hidden');
  const toggleNav = () => {
    if(toggleStatus === 'hidden') {
      setToggleStatus('visible');
      document.getElementById('mobileNav').classList.remove('hidden');
    }
    else {
      setToggleStatus('hidden');
      document.getElementById('mobileNav').classList.add('hidden');
    }
  }

  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <>
        <section className='pt-6 bg-gray-50 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='mb-6'>
              <div className='flex items-center justify-between px-6 py-3.5 bg-white rounded-full'>
                <div className='w-auto'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-auto'>
                      <a href='/'>
                        <img
                          src='images/PSG-Logo.svg'
                          alt=''
                          className='h-20'
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className='w-auto'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-auto hidden lg:block'>
                      <ul className='flex items-center justify-center'>
                        <li className='mr-9'>
                          <a
                              className='inline-block text-sm font-bold text-gray-900 hover:text-green-600 hover:cursor-pointer'
                              onClick={() => loansArea.current.scrollIntoView({behavior: 'smooth'})}
                          >
                            Serviced Loans
                          </a>
                        </li>
                        <li className='mr-9'>
                          <a
                              className='inline-block text-sm font-bold text-gray-900 hover:text-green-600 hover:cursor-pointer'
                              onClick={() => aboutArea.current.scrollIntoView({behavior: 'smooth'})}
                          >
                            About Our Firm
                          </a>
                        </li>
                        <li className='mr-9'>
                          <a
                              className='inline-block text-sm font-bold text-gray-900 hover:text-green-600 hover:cursor-pointer'
                              onClick={() => contactArea.current.scrollIntoView({behavior: 'smooth'})}
                          >
                            Contact Us
                          </a>
                        </li>
                        {/*<li className='mr-9'>*/}
                        {/*  <a*/}
                        {/*      className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'*/}
                        {/*      href='#'*/}
                        {/*  >*/}
                        {/*    Affiliates*/}
                        {/*  </a>*/}
                        {/*</li>*/}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='w-auto'>
                  <div className='flex flex-wrap items-center'>
                    <div className='w-auto hidden lg:block'>
                      <div className='flex flex-wrap -m-2'>
                        <div className='w-full md:w-auto p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold focus:ring-4 rounded-full bg-green-600 hover:bg-green-700 focus:ring-green-200'
                            href='/apply'
                          >
                            Apply Now!
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='w-auto lg:hidden'>
                      <a className='inline-block' href='#' onClick={toggleNav}>
                        <svg
                          className='navbar-burger text-blue-500'
                          width={45}
                          height={45}
                          viewBox='0 0 56 56'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <rect
                            width={56}
                            height={56}
                            rx={28}
                            fill='currentColor'
                          />
                          <path
                            d='M37 32H19M37 24H19'
                            stroke='white'
                            strokeWidth='1.5'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div id="mobileNav" className='hidden navbar-menu fixed top-0 left-0 bottom-0 w-4/6 sm:max-w-xs z-50'>
                <div className='navbar-backdrop fixed inset-0 bg-gray-800 opacity-80' />
                <nav className='relative z-10 px-9 pt-8 bg-white h-full overflow-y-auto'>
                  <div className='flex flex-wrap justify-between h-full'>
                    <div className='w-full'>
                      <div className='flex items-center justify-between -m-2'>
                        <div className='w-auto p-2'>
                          <a className='inline-block' href='/'>
                            <img
                              src='/images/PSG-Logo.svg'
                              className="w-full"
                              alt=''
                            />
                          </a>
                        </div>
                        <div className='w-auto p-2'>
                          <a className='inline-block navbar-burger' href='#' onClick={toggleNav}>
                            <svg
                              width={24}
                              height={24}
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M6 18L18 6M6 6L18 18'
                                stroke='#111827'
                                strokeWidth={2}
                                strokeLinecap='round'
                                strokeLinejoin='round'
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-col justify-center py-8 w-full'>
                      <ul>
                        <li className='mb-9'>
                          <a
                              className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                              href='/'
                          >
                            Home
                          </a>
                        </li>
                        <li className='mb-9'>
                          <a
                              className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                              href='/'
                          >
                            Serviced Loans
                          </a>
                        </li>
                        <li className='mb-9'>
                          <a
                              className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                              href='/'
                          >
                            About our Firm
                          </a>
                        </li>
                        <li className='mb-9'>
                          <a
                              className='inline-block text-sm font-bold text-gray-900 hover:text-gray-700'
                              href='/'
                          >
                            Contact Us
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className='flex flex-col justify-end w-full pb-8'>
                      <div className='flex flex-wrap -m-2'>
                        <div className='w-full p-2'>
                          <a
                              className='block w-full px-4 py-2.5 text-sm text-center text-gray-900 font-bold bg-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-200 border rounded-full'
                            href='https://rs.sapieus.com/login'
                          >
                            Log In
                          </a>
                        </div>
                        <div className='w-full p-2'>
                          <a
                            className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-green-600 hover:bg-green-700 focus:ring-4 focus:ring-blue-200 rounded-full'
                            href='/apply'
                          >
                            Apply Now!
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
              </div>
            </div>
            <div className='mb-8 p-8 bg-white rounded-3xl'>
              <div className='flex flex-wrap lg:items-center -m-8'>
                <div className='w-full md:w-1/2 p-8'>
                  <div className='md:max-w-lg mx-auto'>
                    <span className='inline-block mb-3 text-sm text-blue-500 font-bold uppercase tracking-widest'>
                      YOUR DEDICATED PRIVATE MONEY LENDER.
                    </span>
                    <h1 className='font-heading mb-4 text-5xl text-gray-900 font-black tracking-tight'>
                      Meet your Real Estate Investment Capital needs.
                    </h1>
                    <p className='mb-6 text-xl font-bold'>
                      We've funded over $20,000,000 in the past 30 days! PSG
                      Capital Group is committed to providing reliable and swift
                      private money lending services.
                    </p>
                    <div className='flex flex-wrap -m-2'>
                      <div className='w-full md:w-auto p-2'>
                        <a
                          className='block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-green-600 hover:bg-green-700 focus:ring-4 focus:ring-blue-200 rounded-full'
                          href='/apply'
                        >
                          Apply Now!
                        </a>
                      </div>
                      <div className='w-full md:w-auto p-2'>
                        <a
                          className='block w-full px-4 py-2.5 text-sm text-center text-gray-900 font-bold bg-gray-100 hover:bg-gray-200 focus:ring-4 focus:ring-gray-200 rounded-full hover:cursor-pointer'
                          onClick={() => learnArea.current.scrollIntoView({behavior: 'smooth'})}
                        >
                          Learn More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-1/2 p-8'>
                  <div className='max-w-max mx-auto md:mr-0 bg-white overflow-hidden rounded-3xl'>
                    <img
                      className='mx-auto'
                      src='images/1867187.jpg'
                      alt='PSG Capital Group funds your fix & flips + more.'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-gray-50 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='flex flex-wrap -m-4'>
              <div className='w-full md:w-1/3 p-4'>
                <div className='flex flex-col justify-end p-10 pt-20 lg:pt-40 bg-white h-full border border-gray-100 rounded-3xl'>
                  <h2 className='font-heading text-3xl text-gray-900 font-bold tracking-tight'>
                    <span>More than </span>
                    <span className='text-4xl font-black text-transparent bg-clip-text bg-gradient-green'>
                      $20,000,000
                    </span>
                    <span>&nbsp;funded in the last 30 days</span>
                  </h2>
                </div>
              </div>
              <div className='w-full md:w-1/3 p-4'>
                <div className='flex flex-col justify-end p-10 pt-20 lg:pt-40 bg-white h-full border border-gray-100 rounded-3xl'>
                  <h2 className='font-heading text-3xl text-gray-900 font-bold tracking-tight'>
                    <span>We fund </span>
                    <span className='text-4xl font-black text-transparent bg-clip-text bg-gradient-pink'>
                      numerous
                    </span>
                    <span>&nbsp;different types of private loans.</span>
                  </h2>
                </div>
              </div>
              <div className='w-full md:w-1/3 p-4'>
                <div className='flex flex-col justify-end p-10 pt-20 lg:pt-40 bg-white h-full border border-gray-100 rounded-3xl'>
                  <h2 className='font-heading text-3xl text-gray-900 font-bold tracking-tight'>
                    <span>Get your money&nbsp;</span>
                    <span className='text-4xl font-black text-transparent bg-clip-text bg-gradient-aqua'>
                      quick
                    </span>
                    <span>&nbsp;with our swift approval process.</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-gray-50 overflow-hidden' ref={learnArea}>
          <div className='container mx-auto px-4'>
            <div className='py-16 px-8 bg-white rounded-3xl'>
              <div className='max-w-7xl mx-auto'>
                <h2 className='font-heading mb-20 text-4xl md:text-5xl text-gray-900 font-black tracking-tight'>
                  A lender that works for <span className="text-green-500 underline">you</span>.
                </h2>
                <div className='flex flex-wrap -m-3'>
                  <div className='w-full md:w-1/2 lg:w-1/4 p-3'>
                    <div className='flex flex-wrap items-center -m-3 mb-2'>
                      <div className='w-auto p-3'>
                        <div className='flex items-center justify-center w-16 h-16 text-xl text-gray-900 hover:text-white font-black bg-gray-100 hover:bg-green-600 rounded-full'>
                          1
                        </div>
                      </div>
                      <div className='flex-1 p-3'>
                        <div className='bg-gray-200 h-px' />
                      </div>
                    </div>
                    <div className='md:w-3/4'>
                      <h3 className='font-heading mb-4 text-2xl text-gray-900 font-bold'>
                        Apply Online
                      </h3>
                      <p className='text-gray-700 font-bold'>
                        Fill out our online form. It takes minutes and it's
                        completely free.
                      </p>
                    </div>
                  </div>
                  <div className='w-full md:w-1/2 lg:w-1/4 p-3'>
                    <div className='flex flex-wrap items-center -m-3 mb-2'>
                      <div className='w-auto p-3'>
                        <div className='flex items-center justify-center w-16 h-16 text-xl text-gray-900 hover:text-white font-black bg-gray-100 hover:bg-green-600 rounded-full'>
                          2
                        </div>
                      </div>
                      <div className='flex-1 p-3'>
                        <div className='bg-gray-200 h-px' />
                      </div>
                    </div>
                    <div className='md:w-3/4'>
                      <h3 className='font-heading mb-4 text-2xl text-gray-900 font-bold'>
                        Let's Talk
                      </h3>
                      <p className='text-gray-700 font-bold'>
                        A representative of PSG Capital Group will personally
                        reach out to you.
                      </p>
                    </div>
                  </div>
                  <div className='w-full md:w-1/2 lg:w-1/4 p-3'>
                    <div className='flex flex-wrap items-center -m-3 mb-2'>
                      <div className='w-auto p-3'>
                        <div className='flex items-center justify-center w-16 h-16 text-xl text-gray-900 hover:text-white font-black bg-gray-100 hover:bg-green-600  rounded-full'>
                          3
                        </div>
                      </div>
                      <div className='flex-1 p-3'>
                        <div className='bg-gray-200 h-px' />
                      </div>
                    </div>
                    <div className='md:w-3/4'>
                      <h3 className='font-heading mb-4 text-2xl text-gray-900 font-bold'>
                        Pre-Approval
                      </h3>
                      <p className='text-gray-700 font-bold'>
                        We'll collect and review your documents along with
                        previous experience.
                      </p>
                    </div>
                  </div>
                  <div className='w-full md:w-1/2 lg:w-1/4 p-3'>
                    <div className='flex flex-wrap items-center -m-3 mb-2'>
                      <div className='w-auto p-3'>
                        <div className='flex items-center justify-center w-16 h-16 text-xl text-gray-900 hover:text-white font-black bg-gray-100 hover:bg-green-600 rounded-full'>
                          4
                        </div>
                      </div>
                      <div className='flex-1 lg:hidden p-3'>
                        <div className='bg-gray-200 h-px' />
                      </div>
                    </div>
                    <div className='md:w-3/4'>
                      <h3 className='font-heading mb-4 text-2xl text-gray-900 font-bold'>
                        Funds Delivered
                      </h3>
                      <p className='text-gray-700 font-bold'>
                        Your funding is approved! Money can be delivered in as
                        quick as 14 days.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-16 bg-gray-100 overflow-hidden' ref={loansArea}>
          <div className='container mx-auto px-4'>
            <div className='mb-12 max-w-2xl mx-auto text-center'>
              <h2 className='font-heading text-4xl md:text-5xl text-gray-900 font-black tracking-tight'>
                Serviced Loans
              </h2>
            </div>
            <div className='flex flex-wrap -m-4'>
              <div className='w-full md:w-1/3 p-4'>
                <div className='flex flex-col justify-between p-8 h-full bg-white rounded-3xl'>
                  <div className='flex-initial mb-6'>
                    <span className='inline-block mb-6 text-sm text-blue-500 font-bold uppercase tracking-widest'>
                      $50,000 - $5,000,000
                    </span>
                    <h2 className='font-heading text-4xl text-gray-900 font-black tracking-tight'>
                      Fix &amp; Flips
                    </h2>
                    <p className='mb-8 text-sm text-gray-400 font-bold'>
                      Residential (1-4 Units)
                    </p>
                    <ul>
                      <li className='flex items-center mb-4'>
                        <svg
                            className='mr-2.5'
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                              d='M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333'
                              stroke='#3B82F6'
                              strokeWidth={2}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                          />
                        </svg>
                        <p className='text-gray-900 font-bold'>
                          90% of Purchase, 100% Rehab
                        </p>
                      </li>
                      <li className='flex items-center mb-4'>
                        <svg
                            className='mr-2.5'
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                              d='M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333'
                              stroke='#3B82F6'
                              strokeWidth={2}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                          />
                        </svg>
                        <p className='text-gray-900 font-bold'>
                          Maximum Loan to ARV: 70%
                        </p>
                      </li>
                      <li className='flex items-center mb-4'>
                        <svg
                            className='mr-2.5'
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                              d='M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333'
                              stroke='#3B82F6'
                              strokeWidth={2}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                          />
                        </svg>
                        <p className='text-gray-900 font-bold'>
                          12 months, up to 18 at lenders discretion
                        </p>
                      </li>
                      <li className='flex items-center mb-4'>
                        <svg
                            className='mr-2.5'
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                              d='M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333'
                              stroke='#3B82F6'
                              strokeWidth={2}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                          />
                        </svg>
                        <p className='text-gray-900 font-bold'>
                          Resource: Full Recourse
                        </p>
                      </li>
                      <li className='flex items-center'></li>
                    </ul>
                  </div>
                  <div className='flex-initial'>
                    <div className='flex flex-wrap -m-2'>
                      <div className='w-full p-2'/>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/3 p-4'>
                <div
                    className='flex flex-col justify-between p-8 h-full bg-white border border-gray-200 rounded-3xl shadow-md'>
                  <div className='flex-initial mb-6'>
                    <span className='inline-block mb-6 text-sm text-blue-500 font-bold uppercase tracking-widest'>
                      $50,000 - $5,000,000
                    </span>
                    <h2 className='font-heading text-4xl text-gray-900 font-black tracking-tight'>
                      <span>Ground Up</span>
                    </h2>
                    <p className='mb-8 text-sm text-gray-400 font-bold'>
                      Residential (1-4 Units)
                    </p>
                    <ul>
                      <li className='flex items-center mb-4'>
                        <svg
                            className='mr-2.5'
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                              d='M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333'
                              stroke='#3B82F6'
                              strokeWidth={2}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                          />
                        </svg>
                        <p className='text-gray-900 font-bold'>
                          80% of Project Cost, 100% Of Construction
                        </p>
                      </li>
                      <li className='flex items-center mb-4'>
                        <svg
                            className='mr-2.5'
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                              d='M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333'
                              stroke='#3B82F6'
                              strokeWidth={2}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                          />
                        </svg>
                        <p className='text-gray-900 font-bold'>
                          Up To 75% of Land value OR 60% of purchase if
                          unpermitted
                        </p>
                      </li>
                      <li className='flex items-center mb-4'>
                        <svg
                            className='mr-2.5'
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                              d='M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333'
                              stroke='#3B82F6'
                              strokeWidth={2}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                          />
                        </svg>
                        <p className='text-gray-900 font-bold'>
                          Maximum Loan to ARV: 70%
                        </p>
                      </li>
                      <li className='flex items-center mb-4'>
                        <svg
                            className='mr-2.5'
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                              d='M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333'
                              stroke='#3B82F6'
                              strokeWidth={2}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                          />
                        </svg>
                          <p className='text-gray-900 font-bold'>
                            12 months, up to 18 at lenders discretion
                          </p>
                      </li>
                      <li className='flex items-center'>
                        <svg
                            className='mr-2.5'
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                              d='M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333'
                              stroke='#3B82F6'
                              strokeWidth={2}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                          />
                        </svg>
                        <p className='text-gray-900 font-bold'>
                          Resource: Full Recourse
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className='flex-initial'>
                    <div className='flex flex-wrap -m-2'>
                      <div className='w-full p-2'/>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/3 p-4'>
                <div className='flex flex-col justify-between p-8 h-full bg-white rounded-3xl'>
                  <div className='flex-initial mb-6'>
                    <span className='inline-block mb-6 text-sm text-blue-500 font-bold uppercase tracking-widest'>
                      $75,000 - $2,000,000
                    </span>
                    <h2 className='font-heading text-4xl text-gray-900 font-black tracking-tight'>
                      <span>Rentals</span>
                    </h2>
                    <p className='mb-8 text-sm text-gray-400 font-bold'>
                      Residential (1-4 Units)/Townhouses/PUD Waarrantable Condos
                    </p>
                    <ul>
                      <li className='flex items-center mb-4'>
                        <svg
                            className='mr-2.5'
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                              d='M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333'
                              stroke='#3B82F6'
                              strokeWidth={2}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                          />
                        </svg>
                        <p className='text-gray-900 font-bold'>
                          80% of Total Cost basis if owned less than 3 months.
                        </p>
                      </li>
                      <li className='flex items-center mb-4'>
                        <svg
                            className='mr-2.5'
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                              d='M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333'
                              stroke='#3B82F6'
                              strokeWidth={2}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                          />
                        </svg>
                        <p className='text-gray-900 font-bold'>30 Years</p>
                      </li>
                      <li className='flex items-center mb-4'>
                        <svg
                            className='mr-2.5'
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                              d='M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333'
                              stroke='#3B82F6'
                              strokeWidth={2}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                          />
                        </svg>
                        <p className='text-gray-900 font-bold'>
                          Unleased Units: 90% of Market Rent (Purchase Loans
                          Only)
                        </p>
                      </li>
                      <li className='flex items-center mb-4'></li>
                      <li className='flex items-center'></li>
                    </ul>
                  </div>
                  <div className='flex-initial'>
                    <div className='flex flex-wrap -m-2'>
                      <div className='w-full p-2'/>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/3 p-4'>
                <div className='flex flex-col justify-between p-8 h-full bg-white rounded-3xl'>
                  <div className='flex-initial mb-6'>
                    <span className='inline-block mb-6 text-sm text-blue-500 font-bold uppercase tracking-widest'>
                     $150,000 - $50,000,000
                    </span>
                    <h2 className='font-heading text-4xl text-gray-900 font-black tracking-tight'>
                      <span>Rental Portfolios</span>
                    </h2>
                    <p className='mb-8 text-sm text-gray-400 font-bold'>
                      SF/ 2-4 Unit/ Townhouses / PUD/ Condos
                    </p>
                    <ul>
                      <li className='flex items-center mb-4'>
                        <svg
                            className='mr-2.5'
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                              d='M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333'
                              stroke='#3B82F6'
                              strokeWidth={2}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                          />
                        </svg>
                        <p className='text-gray-900 font-bold'>
                          30-year Fixed Rate (Fully Amortizing)
                        </p>
                      </li>
                      <li className='flex items-center mb-4'>
                        <svg
                            className='mr-2.5'
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                              d='M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333'
                              stroke='#3B82F6'
                              strokeWidth={2}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                          />
                        </svg>
                        <p className='text-gray-900 font-bold'>
                          If owned less than 3 Months, 80% of total cost basis
                        </p>
                      </li>
                      <li className='flex items-center mb-4'>
                        <svg
                            className='mr-2.5'
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                              d='M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333'
                              stroke='#3B82F6'
                              strokeWidth={2}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                          />
                        </svg>
                        <p className='text-gray-900 font-bold'>
                          Purchase/Rate-Term Refi: 80% Cash Out Refi: 75%
                        </p>
                      </li>
                      <li className='flex items-center mb-4'>
                        <svg
                            className='mr-2.5'
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                              d='M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333'
                              stroke='#3B82F6'
                              strokeWidth={2}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                          />
                        </svg>
                        <p className='text-gray-900 font-bold'>
                          5,10 & 30 Years
                        </p>
                      </li>
                      <li className='flex items-center'>
                        <svg
                            className='mr-2.5'
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                              d='M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333'
                              stroke='#3B82F6'
                              strokeWidth={2}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                          />
                        </svg>
                        <p className="text-gray-900 font-bold">
                          Minimum Occupancy Rate of 90%
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className='flex-initial'>
                    <div className='flex flex-wrap -m-2'>
                      <div className='w-full p-2'/>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/3 p-4'>
                <div className='flex flex-col justify-between p-8 h-full bg-white rounded-3xl'>
                  <div className='flex-initial mb-6'>
                    <span className='inline-block mb-6 text-sm text-blue-500 font-bold uppercase tracking-widest'>
                     $250,000 - $3,000,000
                    </span>
                    <h2 className='font-heading text-4xl text-gray-900 font-black tracking-tight'>
                      <span>Multifamily Term</span>
                    </h2>
                    <p className='mb-8 text-sm text-gray-400 font-bold'>
                      5-8 Unit Residential Properties
                    </p>
                    <ul>
                      <li className='flex items-center mb-4'>
                        <svg
                            className='mr-2.5'
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                              d='M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333'
                              stroke='#3B82F6'
                              strokeWidth={2}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                          />
                        </svg>
                        <p className='text-gray-900 font-bold'>
                          30 Year Fixed Mortgage OR 5/6, 7,6, 10/6 Hybrid ARMs (Partial IO or Fully Amortizing)
                        </p>
                      </li>
                      <li className='flex items-center mb-4'>
                        <svg
                            className='mr-2.5'
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                              d='M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333'
                              stroke='#3B82F6'
                              strokeWidth={2}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                          />
                        </svg>
                        <p className='text-gray-900 font-bold'>
                          If owned less than 3 Months, 70% of total cost basis
                        </p>
                      </li>
                      <li className='flex items-center mb-4'>
                        <svg
                            className='mr-2.5'
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                              d='M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333'
                              stroke='#3B82F6'
                              strokeWidth={2}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                          />
                        </svg>
                        <p className='text-gray-900 font-bold'>
                          30 Years
                        </p>
                      </li>
                      <li className='flex items-center mb-4'>
                        <svg
                            className='mr-2.5'
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                              d='M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333'
                              stroke='#3B82F6'
                              strokeWidth={2}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                          />
                        </svg>
                        <p className='text-gray-900 font-bold'>
                          Minimum Occupancy Rate of 90% by Unit Count
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className='flex-initial'>
                    <div className='flex flex-wrap -m-2'>
                      <div className='w-full p-2'/>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/3 p-4'>
                <div className='flex flex-col justify-between p-8 h-full bg-white rounded-3xl'>
                  <div className='flex-initial mb-6'>
                    <span className='inline-block mb-6 text-sm text-blue-500 font-bold uppercase tracking-widest'>
                     $500,000 - $5,000,000
                    </span>
                    <h2 className='font-heading text-4xl text-gray-900 font-black tracking-tight'>
                      <span>Small Balance Multifamily Bridge</span>
                    </h2>
                    <p className='mb-8 text-sm text-gray-400 font-bold'>
                      Residential (5+ Units)
                    </p>
                    <ul>
                      <li className='flex items-center mb-4'>
                        <svg
                            className='mr-2.5'
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                              d='M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333'
                              stroke='#3B82F6'
                              strokeWidth={2}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                          />
                        </svg>
                        <p className='text-gray-900 font-bold'>
                          Purchase Loans: 75% Purchase + 100% Reno
                        </p>
                      </li>
                      <li className='flex items-center mb-4'>
                        <svg
                            className='mr-2.5'
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                              d='M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333'
                              stroke='#3B82F6'
                              strokeWidth={2}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                          />
                        </svg>
                        <p className='text-gray-900 font-bold'>
                          Purchase/Rate-Term Refi: 70% Cash Out Refi: 65%
                        </p>
                      </li>
                      <li className='flex items-center mb-4'>
                        <svg
                            className='mr-2.5'
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                              d='M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333'
                              stroke='#3B82F6'
                              strokeWidth={2}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                          />
                        </svg>
                        <p className='text-gray-900 font-bold'>
                          Up to 24 months + 2, 6 month Extensions
                        </p>
                      </li>
                      <li className='flex items-center mb-4'>
                        <svg
                            className='mr-2.5'
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                              d='M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333'
                              stroke='#3B82F6'
                              strokeWidth={2}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                          />
                        </svg>
                        <p className='text-gray-900 font-bold'>
                          Unleased Units: 90% of Market Rent (Purchase Loans Only)
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className='flex-initial'>
                    <div className='flex flex-wrap -m-2'>
                      <div className='w-full p-2'/>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/3 p-4'>
                <div className='flex flex-col justify-between p-8 h-full bg-white rounded-3xl'>
                  <div className='flex-initial mb-6'>
                    <span className='inline-block mb-6 text-sm text-blue-500 font-bold uppercase tracking-widest'>
                     $75,000 - $2,000,000
                    </span>
                    <h2 className='font-heading text-4xl text-gray-900 font-black tracking-tight'>
                      <span>Stabilized Bridge</span>
                    </h2>
                    <p className='mb-8 text-sm text-gray-400 font-bold'>
                      SF/2-4 Unit/ Townhouses/PUD/Condo
                    </p>
                    <ul>
                      <li className='flex items-center mb-4'>
                        <svg
                            className='mr-2.5'
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                              d='M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333'
                              stroke='#3B82F6'
                              strokeWidth={2}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                          />
                        </svg>
                        <p className='text-gray-900 font-bold'>
                          85% Purchase 100% of Completed Rehab Costs
                        </p>
                      </li>
                      <li className='flex items-center mb-4'>
                        <svg
                            className='mr-2.5'
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                              d='M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333'
                              stroke='#3B82F6'
                              strokeWidth={2}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                          />
                        </svg>
                        <p className='text-gray-900 font-bold'>
                          Max LTV: 70%
                        </p>
                      </li>
                      <li className='flex items-center mb-4'>
                        <svg
                            className='mr-2.5'
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                              d='M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333'
                              stroke='#3B82F6'
                              strokeWidth={2}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                          />
                        </svg>
                        <p className='text-gray-900 font-bold'>
                          12 months, up to 18 at lender discretion
                        </p>
                      </li>
                      <li className='flex items-center mb-4'>
                        <svg
                            className='mr-2.5'
                            width={20}
                            height={20}
                            viewBox='0 0 20 20'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                              d='M4.16699 10.8333L7.50033 14.1667L15.8337 5.83333'
                              stroke='#3B82F6'
                              strokeWidth={2}
                              strokeLinecap='round'
                              strokeLinejoin='round'
                          />
                        </svg>
                        <p className='text-gray-900 font-bold'>
                          Min. DSCR: 1.00x, Min. FICO 660
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div className='flex-initial'>
                    <div className='flex flex-wrap -m-2'>
                      <div className='w-full p-2'/>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/3 p-4'>
                <div
                    className='flex flex-col justify-between p-8 h-full bg-gray-100 border border-gray-200 rounded-3xl shadow-md'>
                  <div className='flex-initial mb-6'>
                    <h2 className='font-heading text-4xl text-gray-900 font-black tracking-tight'>
                      <span>Experienced Builders</span>
                    </h2>
                    <p className='mb-8 text-sm text-gray-400 font-bold'>
                      Up to 90% LTC!
                    </p>
                    <p className="text-gray-900">
                      For experienced builders we now offer up to 90% LTC ( Loan to Project Cost) financing on ground up
                      construction. up to 85% of (hard costs) (5%) in interest reserve escrow.
                    </p>
                  </div>
                  <div className='flex-initial'>
                    <div className='flex flex-wrap -m-2'>
                      <div className='w-full p-2'/>
                    </div>
                  </div>
                </div>
              </div>
              <div className='w-full md:w-1/3 p-4'>
                <div
                    className='flex flex-col justify-between p-8 h-full bg-gray-100 border border-gray-200 rounded-3xl shadow-md'>
                  <div className='flex-initial mb-6'>
                    <h2 className='font-heading text-4xl text-gray-900 font-black tracking-tight'>
                      <span>Ground Up Construction Loans</span>
                    </h2>
                    <p className='mb-8 text-sm text-gray-400 font-bold'>
                      Up to 80% Loan-to-cost!
                    </p>
                    <p className="text-gray-900">
                      First time investors with a general contractor license with prior experience in building for other clients can receive up to 80% LTC.
                    </p>
                  </div>
                  <div className='flex-initial'>
                    <div className='flex flex-wrap -m-2'>
                      <div className='w-full p-2'/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
        <section className='py-10 bg-white overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='py-16 px-8 text-center bg-gradient bg-gradient-to-br from-green-500 to-green-300 rounded-3xl'>
              <div className='md:max-w-lg mx-auto'>
                <h2 className='font-heading mb-6 text-4xl md:text-5xl text-white font-black tracking-tight'>
                  Submit your First Deal.
                </h2>
                <p className='mb-10 text-xl text-blue-100 font-bold'>
                  Fast, Reliable, Flexible, and Experienced. You're choosing a
                  lender that works for you.
                </p>
                <div className='flex flex-wrap justify-center -m-2 mb-4'>
                  <div className='w-auto p-2'>
                    <a
                        className='block w-full px-8 py-3.5 text-lg text-center text-white font-bold bg-gray-900 hover:bg-gray-800 focus:ring-4 focus:ring-gray-600 rounded-full'
                        href='/apply'
                    >
                      Get your Pre-Approval!
                    </a>
                  </div>
                </div>
                <p className='text-xs text-blue-100'>
                  Completely free. We're committed to you.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-gray-50 overflow-hidden' ref={aboutArea}>
          <div className='container mx-auto px-4'>
            <div className='relative pt-16 lg:pt-40 lg:pb-44 bg-white overflow-hidden rounded-3xl'>
              <div className='max-w-7xl mx-auto'>
                <div className='flex flex-wrap items-center -m-8'>
                  <div className='w-full md:w-1/2 p-8 mb-16 lg:mb-0'>
                    <div className='md:max-w-xl px-8'>
                      <span className='inline-block mb-5 text-sm text-blue-500 font-bold uppercase tracking-widest'>
                        ABOUT PSG CAPITAL GROUP
                      </span>
                      <h2 className='font-heading mb-6 text-4xl md:text-5xl lg:text-6xl text-gray-900 font-black tracking-tight'>
                        Reliable Funding from PSG.
                      </h2>
                      <p className='mb-6 text-gray-500 font-bold'>
                        PSG Capital Group has more than 35 years of lending
                        experience. We have grown into one of the nation’s
                        leading direct capital lenders. We invest and partner
                        with a number of startups, real estate investors and
                        companies that are in need of capital that are doing
                        great things in their respective fields. When it comes
                        to raising capital, there is no such thing as
                        approaching us too early. The earlier we come on board
                        the better it is for our clients. We offer 24/7 customer
                        service, which is critical for any business and the
                        reason that our clients continue to do business with us.
                        PSG Capital prides itself on trying to keep the process
                        as simple as possible and maintaining long-term
                        relationships with all of our clients.
                      </p>
                      <div className='md:max-w-md'>
                        <div className='flex flex-wrap -m-2'>
                          <div className='w-auto p-2'>
                            <div className='flex flex-wrap px-3 py-2 bg-gray-100 rounded-full'>
                              <div className='w-auto mr-2 pt-1'>
                                <svg
                                    width={12}
                                    height={12}
                                    viewBox='0 0 12 12'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                      d='M8.095 1H3.905C2.085 1 1 2.085 1 3.905V8.09C1 9.915 2.085 11 3.905 11H8.09C9.91 11 10.995 9.915 10.995 8.095V3.905C11 2.085 9.915 1 8.095 1ZM8.39 4.85L5.555 7.685C5.485 7.755 5.39 7.795 5.29 7.795C5.19 7.795 5.095 7.755 5.025 7.685L3.61 6.27C3.465 6.125 3.465 5.885 3.61 5.74C3.755 5.595 3.995 5.595 4.14 5.74L5.29 6.89L7.86 4.32C8.005 4.175 8.245 4.175 8.39 4.32C8.535 4.465 8.535 4.7 8.39 4.85Z'
                                      fill='#3B82F6'
                                  />
                                </svg>
                              </div>
                              <div className='flex-1'>
                                <p className='text-sm text-gray-900 font-bold'>
                                  Fast
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className='w-auto p-2'>
                            <div className='flex flex-wrap px-3 py-2 bg-gray-100 rounded-full'>
                              <div className='w-auto mr-2 pt-1'>
                                <svg
                                    width={12}
                                    height={12}
                                    viewBox='0 0 12 12'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                      d='M8.095 1H3.905C2.085 1 1 2.085 1 3.905V8.09C1 9.915 2.085 11 3.905 11H8.09C9.91 11 10.995 9.915 10.995 8.095V3.905C11 2.085 9.915 1 8.095 1ZM8.39 4.85L5.555 7.685C5.485 7.755 5.39 7.795 5.29 7.795C5.19 7.795 5.095 7.755 5.025 7.685L3.61 6.27C3.465 6.125 3.465 5.885 3.61 5.74C3.755 5.595 3.995 5.595 4.14 5.74L5.29 6.89L7.86 4.32C8.005 4.175 8.245 4.175 8.39 4.32C8.535 4.465 8.535 4.7 8.39 4.85Z'
                                    fill='#3B82F6'
                                  />
                                </svg>
                              </div>
                              <div className='flex-1'>
                                <p className='text-sm text-gray-900 font-bold'>
                                  Flexible
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className='w-auto p-2'>
                            <div className='flex flex-wrap px-3 py-2 bg-gray-100 rounded-full'>
                              <div className='w-auto mr-2 pt-1'>
                                <svg
                                  width={12}
                                  height={12}
                                  viewBox='0 0 12 12'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M8.095 1H3.905C2.085 1 1 2.085 1 3.905V8.09C1 9.915 2.085 11 3.905 11H8.09C9.91 11 10.995 9.915 10.995 8.095V3.905C11 2.085 9.915 1 8.095 1ZM8.39 4.85L5.555 7.685C5.485 7.755 5.39 7.795 5.29 7.795C5.19 7.795 5.095 7.755 5.025 7.685L3.61 6.27C3.465 6.125 3.465 5.885 3.61 5.74C3.755 5.595 3.995 5.595 4.14 5.74L5.29 6.89L7.86 4.32C8.005 4.175 8.245 4.175 8.39 4.32C8.535 4.465 8.535 4.7 8.39 4.85Z'
                                    fill='#3B82F6'
                                  />
                                </svg>
                              </div>
                              <div className='flex-1'>
                                <p className='text-sm text-gray-900 font-bold'>
                                  Reliable
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className='w-auto p-2'>
                            <div className='flex flex-wrap px-3 py-2 bg-gray-100 rounded-full'>
                              <div className='w-auto mr-2 pt-1'>
                                <svg
                                  width={12}
                                  height={12}
                                  viewBox='0 0 12 12'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M8.095 1H3.905C2.085 1 1 2.085 1 3.905V8.09C1 9.915 2.085 11 3.905 11H8.09C9.91 11 10.995 9.915 10.995 8.095V3.905C11 2.085 9.915 1 8.095 1ZM8.39 4.85L5.555 7.685C5.485 7.755 5.39 7.795 5.29 7.795C5.19 7.795 5.095 7.755 5.025 7.685L3.61 6.27C3.465 6.125 3.465 5.885 3.61 5.74C3.755 5.595 3.995 5.595 4.14 5.74L5.29 6.89L7.86 4.32C8.005 4.175 8.245 4.175 8.39 4.32C8.535 4.465 8.535 4.7 8.39 4.85Z'
                                    fill='#3B82F6'
                                  />
                                </svg>
                              </div>
                              <div className='flex-1'>
                                <p className='text-sm text-gray-900 font-bold'>
                                  Experienced
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img
                className='relative z-10 md:absolute md:top-1/2 md:right-0 md:w-1/2 md:transform md:-translate-y-1/2 xl:w-auto mx-auto md:mr-0 pl-8 pb-8 md:pl-0 md:pb-0 max-w-2xl'
                src='images/0123.jpg'
                alt=''
              />
            </div>
          </div>
        </section>
        <section className='py-10 bg-gray-50 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='relative py-16 px-8 bg-white overflow-hidden rounded-3xl'>
              <div className='absolute top-0 left-1/2 min-w-max transform -translate-x-1/2'>
                <div className='absolute bg-gradient-radial-white w-full h-full' />
                <img src='zanrly-assets/images/faq/pattern-gray.png' alt='' />
              </div>
              <div className='relative z-10'>
                <div className='mb-10 md:max-w-xl mx-auto text-center'>
                  <span className='inline-block mb-5 text-sm text-blue-500 font-bold uppercase tracking-widest'>
                    Frequently asked questions
                  </span>
                  <h2 className='font-heading mb-6 text-4xl md:text-5xl lg:text-6xl text-gray-900 font-black tracking-tight'>
                    Common Lending Questions
                  </h2>
                </div>
                <div className='max-w-3xl mx-auto'>
                  <div className='flex flex-wrap -m-3 mb-10'>
                    <div className='w-full p-3'>
                      <a
                        className='block p-10 bg-gray-100 rounded-3xl'
                        onClick={() => toggleShownFaqElement(1)}
                      >
                        <div className='flex flex-wrap -m-2'>
                          <div className='flex-1 p-2'>
                            <h3 className='font-heading mb-4 text-xl text-gray-900 font-black'>
                              What is a private money loan?
                            </h3>
                            {shownFaqElement === 1 && (
                                <p className='text-gray-500 font-bold'>
                                  A private money loan is a short-term loan or long-term loan secured by real estate. They are typically funded by private investors rather than conventional lenders like banks. PSG Capital Group is a direct private lender.
                                </p>
                            )}
                          </div>
                          <div className='w-auto p-2'>
                            {shownFaqElement === 1 ? (
                                <svg
                                    width={24}
                                    height={24}
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                      d='M6.07928 15.82L12.3093 15.82L17.9193 15.82C18.8793 15.82 19.3593 14.66 18.6793 13.98L13.4993 8.80001C12.6693 7.97001 11.3193 7.97001 10.4893 8.80001L8.51928 10.77L5.30927 13.98C4.63927 14.66 5.11928 15.82 6.07928 15.82Z'
                                      fill='#D1D5DB'
                                  />
                                </svg>
                            ) : (
                                <svg
                                    width={24}
                                    height={24}
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                      d='M17.9207 8.17999H11.6907H6.08072C5.12072 8.17999 4.64073 9.33999 5.32073 10.02L10.5007 15.2C11.3307 16.03 12.6807 16.03 13.5107 15.2L15.4807 13.23L18.6907 10.02C19.3607 9.33999 18.8807 8.17999 17.9207 8.17999Z'
                                      fill='#D1D5DB'
                                  />
                                </svg>
                            )}
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className='w-full p-3'>
                      <a
                        className='block p-10 bg-gray-100 rounded-3xl hover:cursor-pointer'
                        onClick={() => toggleShownFaqElement(2)}
                      >
                        <div className='flex flex-wrap -m-2'>
                          <div className='flex-1 p-2'>
                            <h3 className='font-heading text-xl text-gray-900 font-black'>
                              How fast can I receive a private money loan?
                            </h3>
                            {shownFaqElement === 2 && (
                                <p className='text-gray-500 font-bold'>
                                  Once all documentation is received by PSG Capital Group and approved by our underwriting team a term sheet is sent out for signature once the term sheet is signed a closing date will be set. The turnaround process can be within 3 days or less from a complete file.                                </p>
                            )}
                          </div>
                          <div className='w-auto p-2'>
                            {shownFaqElement !== 2 ? (
                                <svg
                                    width={24}
                                    height={24}
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                      d='M17.9207 8.17999H11.6907H6.08072C5.12072 8.17999 4.64073 9.33999 5.32073 10.02L10.5007 15.2C11.3307 16.03 12.6807 16.03 13.5107 15.2L15.4807 13.23L18.6907 10.02C19.3607 9.33999 18.8807 8.17999 17.9207 8.17999Z'
                                      fill='#D1D5DB'
                                  />
                                </svg>
                            ) : (
                                <svg
                                    width={24}
                                    height={24}
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                      d='M6.07928 15.82L12.3093 15.82L17.9193 15.82C18.8793 15.82 19.3593 14.66 18.6793 13.98L13.4993 8.80001C12.6693 7.97001 11.3193 7.97001 10.4893 8.80001L8.51928 10.77L5.30927 13.98C4.63927 14.66 5.11928 15.82 6.07928 15.82Z'
                                      fill='#D1D5DB'
                                  />
                                </svg>
                            )}
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className='w-full p-3'>
                      <a
                          className='block p-10 bg-gray-100 rounded-3xl hover:cursor-pointer'
                          onClick={() => toggleShownFaqElement(3)}
                      >
                        <div className='flex flex-wrap -m-2'>
                          <div className='flex-1 p-2'>
                            <h3 className='font-heading text-xl text-gray-900 font-black'>
                              How do you determine the amount to loan?
                            </h3>
                            {shownFaqElement === 3 && (
                                <p className='text-gray-500 font-bold'>
                                  There are numerous factors that determine the amount of the loan, depending on experience and credit and the type of loan that is being requested.                                </p>
                            )}
                          </div>
                          <div className='w-auto p-2'>
                            {shownFaqElement !== 3 ? (
                                <svg
                                    width={24}
                                    height={24}
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                      d='M17.9207 8.17999H11.6907H6.08072C5.12072 8.17999 4.64073 9.33999 5.32073 10.02L10.5007 15.2C11.3307 16.03 12.6807 16.03 13.5107 15.2L15.4807 13.23L18.6907 10.02C19.3607 9.33999 18.8807 8.17999 17.9207 8.17999Z'
                                      fill='#D1D5DB'
                                  />
                                </svg>
                            ) : (
                                <svg
                                    width={24}
                                    height={24}
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                      d='M6.07928 15.82L12.3093 15.82L17.9193 15.82C18.8793 15.82 19.3593 14.66 18.6793 13.98L13.4993 8.80001C12.6693 7.97001 11.3193 7.97001 10.4893 8.80001L8.51928 10.77L5.30927 13.98C4.63927 14.66 5.11928 15.82 6.07928 15.82Z'
                                      fill='#D1D5DB'
                                  />
                                </svg>
                            )}
                          </div>
                        </div>
                      </a>
                    </div>
                    <div className='w-full p-3'>
                      <a
                        className='block p-10 bg-gray-100 rounded-3xl hover:cursor-pointer'
                        onClick={() => toggleShownFaqElement(4)}
                      >
                        <div className='flex flex-wrap -m-2'>
                          <div className='flex-1 p-2'>
                            <h3 className='font-heading text-xl text-gray-900 font-black'>
                              What type of documents do I need?
                            </h3>
                            {shownFaqElement === 4 && (
                                <p className='text-gray-500 font-bold'>
                                  Our underwriting team at PSG Capital Group will work directly with you to obtain the necessary documents to fulfill your loan. Our process is simple and painless.                                </p>
                            )}

                          </div>
                          <div className='w-auto p-2'>
                            {shownFaqElement !== 4 ? (
                                <svg
                                    width={24}
                                    height={24}
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                      d='M17.9207 8.17999H11.6907H6.08072C5.12072 8.17999 4.64073 9.33999 5.32073 10.02L10.5007 15.2C11.3307 16.03 12.6807 16.03 13.5107 15.2L15.4807 13.23L18.6907 10.02C19.3607 9.33999 18.8807 8.17999 17.9207 8.17999Z'
                                      fill='#D1D5DB'
                                  />
                                </svg>
                            ) : (
                                <svg
                                    width={24}
                                    height={24}
                                    viewBox='0 0 24 24'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                      d='M6.07928 15.82L12.3093 15.82L17.9193 15.82C18.8793 15.82 19.3593 14.66 18.6793 13.98L13.4993 8.80001C12.6693 7.97001 11.3193 7.97001 10.4893 8.80001L8.51928 10.77L5.30927 13.98C4.63927 14.66 5.11928 15.82 6.07928 15.82Z'
                                      fill='#D1D5DB'
                                  />
                                </svg>
                            )}
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <p className='text-gray-500 font-bold text-center'>
                    <span>Still have any questions? Contact us below.</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-gray-50 overflow-hidden' ref={contactArea}>
          <div className='container mx-auto px-4'>
            <div className='py-16 px-8 bg-white overflow-hidden rounded-3xl'>
              <div className='max-w-5xl mx-auto'>
                <div className='mb-10 md:max-w-md mx-auto text-center'>
                  <span className='inline-block mb-4 text-sm text-blue-500 font-bold uppercase tracking-widest'>
                    SUBMIT YOUR INQUIRY
                  </span>
                  <h2 className='font-heading mb-6 text-4xl md:text-5xl lg:text-6xl text-gray-900 font-black tracking-tight'>
                    Contact Us
                  </h2>
                </div>
                {/*<form className='p-10 bg-gray-100 border border-gray-100 rounded-3xl'>*/}
                {/*  <div className='flex flex-wrap -m-5 mb-1'>*/}
                {/*    <div className='w-full md:w-1/2 p-5'>*/}
                {/*      <label*/}
                {/*        className='block mb-2 text-sm text-gray-500 font-bold'*/}
                {/*        htmlFor='contactLightReverseInput2-1'*/}
                {/*      >*/}
                {/*        Full Name*/}
                {/*      </label>*/}
                {/*      <input*/}
                {/*        className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-blue-200 rounded-full'*/}
                {/*        id='contactLightReverseInput2-1'*/}
                {/*        type='text'*/}
                {/*        placeholder='Enter your name'*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*    <div className='w-full md:w-1/2 p-5'>*/}
                {/*      <label*/}
                {/*        className='block mb-2 text-sm text-gray-500 font-bold'*/}
                {/*        htmlFor='contactLightReverseInput2-2'*/}
                {/*      >*/}
                {/*        Email address*/}
                {/*      </label>*/}
                {/*      <input*/}
                {/*        className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-blue-200 rounded-full'*/}
                {/*        id='contactLightReverseInput2-2'*/}
                {/*        type='text'*/}
                {/*        placeholder='Email address'*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*  <div className='flex flex-wrap -m-5'>*/}
                {/*    <div className='w-full md:w-1/2 p-5'>*/}
                {/*      <label*/}
                {/*        className='block mb-2 text-sm text-gray-500 font-bold'*/}
                {/*        htmlFor='contactLightReverseInput2-3'*/}
                {/*      >*/}
                {/*        Phone*/}
                {/*      </label>*/}
                {/*      <input*/}
                {/*        className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-blue-200 rounded-full'*/}
                {/*        id='contactLightReverseInput2-3'*/}
                {/*        type='text'*/}
                {/*        placeholder='Enter your phone number'*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*    <div className='w-full md:w-1/2 p-5'>*/}
                {/*      <label*/}
                {/*        className='block mb-2 text-sm text-gray-500 font-bold'*/}
                {/*        htmlFor='contactLightReverseInput2-4'*/}
                {/*      >*/}
                {/*        Subject*/}
                {/*      </label>*/}
                {/*      <input*/}
                {/*        className='appearance-none px-6 py-3.5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-blue-200 rounded-full'*/}
                {/*        id='contactLightReverseInput2-4'*/}
                {/*        type='text'*/}
                {/*        placeholder='Type your subject'*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*  <div className='flex flex-wrap -m-3.5'>*/}
                {/*    <div className='w-full p-3.5'>*/}
                {/*      <label*/}
                {/*        className='block mb-2 text-sm text-gray-500 font-bold'*/}
                {/*        htmlFor='contactLightReverseInput2-5'*/}
                {/*      >*/}
                {/*        Message*/}
                {/*      </label>*/}
                {/*      <textarea*/}
                {/*        className='appearance-none px-6 py-5 w-full text-lg text-gray-500 font-bold bg-white placeholder-gray-500 outline-none focus:ring-4 focus:ring-blue-200 rounded-3xl'*/}
                {/*        id='contactLightReverseInput2-5'*/}
                {/*        type='text'*/}
                {/*        rows={8}*/}
                {/*        placeholder='Enter your message'*/}
                {/*        defaultValue={''}*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*    <div className='w-full p-3.5'>*/}
                {/*      <div className='flex flex-wrap items-center -m-2'>*/}
                {/*        <div className='w-full md:w-1/2 p-2'>*/}
                {/*          <div className='flex'>*/}
                {/*            <input*/}
                {/*              className='opacity-0 absolute h-5 w-5'*/}
                {/*              id='contactLightReverseCheckbox2-1'*/}
                {/*              type='checkbox'*/}
                {/*            />*/}
                {/*            <div className='flex flex-shrink-0 justify-center items-center w-6 h-6 mr-4 text-transparent bg-white border border-gray-200 rounded-md'>*/}
                {/*              <svg*/}
                {/*                width={9}*/}
                {/*                height={7}*/}
                {/*                viewBox='0 0 9 7'*/}
                {/*                fill='none'*/}
                {/*                xmlns='http://www.w3.org/2000/svg'*/}
                {/*              >*/}
                {/*                <path*/}
                {/*                  d='M0.603516 3.77075L2.68685 5.85409L7.89518 0.645752'*/}
                {/*                  stroke='currentColor'*/}
                {/*                  strokeLinecap='round'*/}
                {/*                  strokeLinejoin='round'*/}
                {/*                />*/}
                {/*              </svg>*/}
                {/*            </div>*/}
                {/*            <label*/}
                {/*              className='text-sm text-gray-500 font-bold'*/}
                {/*              htmlFor='contactLightReverseCheckbox2-1'*/}
                {/*            >*/}
                {/*              I’d like to occasionally receive other*/}
                {/*              communication from PSG Capital Group, such as new*/}
                {/*              offerings and financial news.*/}
                {/*            </label>*/}
                {/*          </div>*/}
                {/*        </div>*/}
                {/*        <div className='w-full md:w-1/2 p-2'>*/}
                {/*          <div className='flex flex-wrap md:justify-end -m-2'>*/}
                {/*            <div className='w-full md:w-auto p-2'>*/}
                {/*              <a*/}
                {/*                className='block w-full px-8 md:px-16 py-3.5 text-lg text-center text-white font-bold bg-green-600 hover:bg-green-700 focus:ring-4 focus:ring-blue-200 rounded-full'*/}
                {/*                href='#'*/}
                {/*              >*/}
                {/*                Send Message*/}
                {/*              </a>*/}
                {/*            </div>*/}
                {/*          </div>*/}
                {/*        </div>*/}
                {/*      </div>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</form>*/}
                <div className='flex flex-wrap -m-4'>
                  <div className='w-full p-4 md:w-1/3'>
                    <div className='p-10 text-center h-full rounded-3xl'>
                      <div className='flex items-center justify-center mb-6 w-12 h-12 mx-auto bg-gray-100 rounded-xl'>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M14 16C14 17.77 13.23 19.37 12 20.46C10.94 21.42 9.54 22 8 22C4.69 22 2 19.31 2 16C2 13.9753 3.01397 12.1814 4.5554 11.0973C4.80358 10.9228 5.1393 11.0422 5.27324 11.3144C6.21715 13.2332 7.95419 14.6699 10.02 15.23C10.65 15.41 11.31 15.5 12 15.5C12.4872 15.5 12.9539 15.4538 13.4074 15.3687C13.6958 15.3146 13.9828 15.4995 13.9955 15.7925C13.9985 15.862 14 15.9314 14 16Z'
                            fill='#3B82F6'
                          />
                          <path
                            d='M18 8C18 8.78 17.85 9.53 17.58 10.21C16.89 11.95 15.41 13.29 13.58 13.79C13.08 13.93 12.55 14 12 14C11.45 14 10.92 13.93 10.42 13.79C8.59 13.29 7.11 11.95 6.42 10.21C6.15 9.53 6 8.78 6 8C6 4.69 8.69 2 12 2C15.31 2 18 4.69 18 8Z'
                            fill='#3B82F6'
                          />
                          <path
                            d='M22 16C22 19.31 19.31 22 16 22C15.2555 22 14.5393 21.8643 13.8811 21.6141C13.5624 21.4929 13.503 21.0851 13.7248 20.8262C14.8668 19.4938 15.5 17.786 15.5 16C15.5 15.66 15.47 15.32 15.42 15V15C15.3902 14.8155 15.4844 14.6342 15.6478 14.5437C16.9719 13.8107 18.0532 12.6875 18.727 11.3153C18.8609 11.0427 19.1968 10.923 19.4452 11.0977C20.9863 12.1818 22 13.9755 22 16Z'
                            fill='#3B82F6'
                          />
                        </svg>
                      </div>
                      <h3 className='font-heading mb-2 text-xl text-gray-900 font-black'>
                        Call Us
                      </h3>
                      <p className='text-gray-500 font-bold'>(561) 208-6560</p>
                      <p className='text-gray-500 font-bold'>
                        Toll Free:&nbsp;844-PSGMONEY
                      </p>
                    </div>
                  </div>
                  <div className='w-full p-4 md:w-1/3'>
                    <div className='p-10 text-center h-full rounded-3xl'>
                      <div className='flex items-center justify-center mb-6 w-12 h-12 mx-auto bg-gray-100 rounded-xl'>
                        <svg
                            width={24}
                            height={24}
                            viewBox='0 0 24 24'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                              d='M14 16C14 17.77 13.23 19.37 12 20.46C10.94 21.42 9.54 22 8 22C4.69 22 2 19.31 2 16C2 13.9753 3.01397 12.1814 4.5554 11.0973C4.80358 10.9228 5.1393 11.0422 5.27324 11.3144C6.21715 13.2332 7.95419 14.6699 10.02 15.23C10.65 15.41 11.31 15.5 12 15.5C12.4872 15.5 12.9539 15.4538 13.4074 15.3687C13.6958 15.3146 13.9828 15.4995 13.9955 15.7925C13.9985 15.862 14 15.9314 14 16Z'
                              fill='#3B82F6'
                          />
                          <path
                              d='M18 8C18 8.78 17.85 9.53 17.58 10.21C16.89 11.95 15.41 13.29 13.58 13.79C13.08 13.93 12.55 14 12 14C11.45 14 10.92 13.93 10.42 13.79C8.59 13.29 7.11 11.95 6.42 10.21C6.15 9.53 6 8.78 6 8C6 4.69 8.69 2 12 2C15.31 2 18 4.69 18 8Z'
                              fill='#3B82F6'
                          />
                          <path
                              d='M22 16C22 19.31 19.31 22 16 22C15.2555 22 14.5393 21.8643 13.8811 21.6141C13.5624 21.4929 13.503 21.0851 13.7248 20.8262C14.8668 19.4938 15.5 17.786 15.5 16C15.5 15.66 15.47 15.32 15.42 15V15C15.3902 14.8155 15.4844 14.6342 15.6478 14.5437C16.9719 13.8107 18.0532 12.6875 18.727 11.3153C18.8609 11.0427 19.1968 10.923 19.4452 11.0977C20.9863 12.1818 22 13.9755 22 16Z'
                              fill='#3B82F6'
                          />
                        </svg>
                      </div>
                      <h3 className='font-heading mb-2 text-xl text-gray-900 font-black'>
                        Email Us
                      </h3>
                      <p className='text-gray-500 font-bold'>info@psgcapitalgroup.com</p>
                    </div>
                  </div>
                  <div className='w-full p-4 md:w-1/3'>
                    <div className='p-10 text-center h-full rounded-3xl'>
                      <div className='flex items-center justify-center mb-6 w-12 h-12 mx-auto bg-gray-100 rounded-xl'>
                        <svg
                          width={24}
                          height={24}
                          viewBox='0 0 24 24'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M14 16C14 17.77 13.23 19.37 12 20.46C10.94 21.42 9.54 22 8 22C4.69 22 2 19.31 2 16C2 13.9753 3.01397 12.1814 4.5554 11.0973C4.80358 10.9228 5.1393 11.0422 5.27324 11.3144C6.21715 13.2332 7.95419 14.6699 10.02 15.23C10.65 15.41 11.31 15.5 12 15.5C12.4872 15.5 12.9539 15.4538 13.4074 15.3687C13.6958 15.3146 13.9828 15.4995 13.9955 15.7925C13.9985 15.862 14 15.9314 14 16Z'
                            fill='#3B82F6'
                          />
                          <path
                            d='M18 8C18 8.78 17.85 9.53 17.58 10.21C16.89 11.95 15.41 13.29 13.58 13.79C13.08 13.93 12.55 14 12 14C11.45 14 10.92 13.93 10.42 13.79C8.59 13.29 7.11 11.95 6.42 10.21C6.15 9.53 6 8.78 6 8C6 4.69 8.69 2 12 2C15.31 2 18 4.69 18 8Z'
                            fill='#3B82F6'
                          />
                          <path
                            d='M22 16C22 19.31 19.31 22 16 22C15.2555 22 14.5393 21.8643 13.8811 21.6141C13.5624 21.4929 13.503 21.0851 13.7248 20.8262C14.8668 19.4938 15.5 17.786 15.5 16C15.5 15.66 15.47 15.32 15.42 15V15C15.3902 14.8155 15.4844 14.6342 15.6478 14.5437C16.9719 13.8107 18.0532 12.6875 18.727 11.3153C18.8609 11.0427 19.1968 10.923 19.4452 11.0977C20.9863 12.1818 22 13.9755 22 16Z'
                            fill='#3B82F6'
                          />
                        </svg>
                      </div>
                      <h3 className='font-heading mb-2 text-xl text-gray-900 font-black'>
                        Address
                      </h3>
                      <p className='text-gray-500 font-bold'>
                        3350 NW 2nd Ave, Suite B2, Boca Raton, Florida 33431
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='py-10 bg-gray-50 overflow-hidden'>
          <div className='container mx-auto px-4'>
            <div className='py-16 px-8 bg-white rounded-3xl'>
              <div className='flex flex-col justify-center'>
                <a className='inline-block max-w-max mx-auto mb-10' href='/'>
                  <img
                    src='/images/PSG-Logo.svg'
                    alt='PSG Capital Group Official Logo'
                    className='text-center m-auto w-1/2'
                  />
                </a>
                <ul className='-m-6 mb-7 text-center'>
                  <li className='inline-flex p-6'>
                    <a
                      className='inline-block text-gray-500 hover:text-gray-600 font-bold hover:cursor-pointer'
                      onClick={() => loansArea.current.scrollIntoView({behavior: 'smooth'})}
                    >
                      Serviced Loans
                    </a>
                  </li>
                  <li className='inline-flex p-6'>
                    <a
                      className='inline-block text-gray-500 hover:text-gray-600 font-bold hover:cursor-pointer'
                      onClick={() => aboutArea.current.scrollIntoView({behavior: 'smooth'})}

                    >
                      About our Firm
                    </a>
                  </li>
                  <li className='inline-flex p-6'>
                    <a
                      className='inline-block text-gray-500 hover:text-gray-600 font-bold'
                      href='/apply'
                    >
                      Pre-Approval Form
                    </a>
                  </li>
                  <li className='inline-flex p-6'>
                    <a
                      className='inline-block text-gray-500 hover:text-gray-600 font-bold hover:cursor-pointer'
                      href='#'
                      onClick={() => contactArea.current.scrollIntoView({behavior: 'smooth'})}
                    >
                      Contact Us
                    </a>
                  </li>
                  {/*<li className='inline-flex p-6'>*/}
                  {/*  <a*/}
                  {/*    className='inline-block text-gray-500 hover:text-gray-600 font-bold'*/}
                  {/*    href='#'*/}
                  {/*  >*/}
                  {/*    Affiliates*/}
                  {/*  </a>*/}
                  {/*</li>*/}
                </ul>
                <div className='flex flex-wrap justify-center -m-4 mb-12'>
                  {/*<div className='w-auto p-4'>*/}
                  {/*  <a className='block' href='#'>*/}
                  {/*    <svg className="h-8 w-8 text-green-500" width="24" height="24" viewBox="0 0 24 24"*/}
                  {/*         strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"*/}
                  {/*         strokeLinejoin="round">*/}
                  {/*      <path stroke="none" d="M0 0h24v24H0z"/>*/}
                  {/*      <rect x="4" y="4" width="16" height="16" rx="4"/>*/}
                  {/*      <circle cx="12" cy="12" r="3"/>*/}
                  {/*      <line x1="16.5" y1="7.5" x2="16.5" y2="7.501"/>*/}
                  {/*    </svg>*/}
                  {/*  </a>*/}
                  {/*</div>*/}
                  {/*<div className='w-auto p-4'>*/}
                  {/*  <a className='block' href='#'>*/}
                  {/*    <svg className="h-8 w-8 text-green-500" width="24" height="24" viewBox="0 0 24 24"*/}
                  {/*         strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"*/}
                  {/*         strokeLinejoin="round">*/}
                  {/*      <path stroke="none" d="M0 0h24v24H0z"/>*/}
                  {/*      <path*/}
                  {/*          d="M22 4.01c-1 .49-1.98.689-3 .99-1.121-1.265-2.783-1.335-4.38-.737S11.977 6.323 12 8v1c-3.245.083-6.135-1.395-8-4 0 0-4.182 7.433 4 11-1.872 1.247-3.739 2.088-6 2 3.308 1.803 6.913 2.423 10.034 1.517 3.58-1.04 6.522-3.723 7.651-7.742a13.84 13.84 0 0 0 .497 -3.753C20.18 7.773 21.692 5.25 22 4.009z"/>*/}
                  {/*    </svg>*/}
                  {/*  </a>*/}
                  {/*</div>*/}
                  <div className='w-auto p-4'>
                    <a className='block' href='https://www.facebook.com/psgcapitalgroup' target='_blank'>
                      <svg className="h-8 w-8 text-green-500"  width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" /></svg>                    </a>
                  </div>
                </div>
                <p className='text-center font-bold'>
                  <span>Ⓒ Copyright. All rights reserved by </span>
                  <a className='text-blue-500 hover:text-blue-600' href='#'>
                    PSG Capital Group
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

